import React from "react";

import { Box, Layer, Spinner, Text } from "grommet";

export default function LoaderModal({ size = "xsmall", message, onClose }) {

    return (
        <Layer position={"center"}
               style={{ borderRadius: 24 }}
               modal
               responsive
        >
            <Box direction={"row"} gap={"small"} pad={"large"} align={"center"}>
                <Box>
                    <Spinner pad={size}
                             border={[
                                 { side: "all", color: "background-contrast", size: "medium" },
                                 { side: "right", color: "brand", size: "medium" },
                                 { side: "top", color: "brand", size: "medium" },
                                 { side: "left", color: "brand", size: "medium" },
                             ]}
                    />
                </Box>
                <Text size={"xsmall"} style={{ marginBottom: 1 }}>
                    { message }
                </Text>
            </Box>
        </Layer>
    );

}
