import React from "react";

export const DEFAULT_STATE = {
    user: undefined,
    users: [],
    themeMode: "light",
    authPath: undefined,
};

export const AppContext = React.createContext(DEFAULT_STATE);

export const Action = {
    LOAD_USERS_DATA: "LOAD_USERS_DATA",
    SIGN_IN: "SIGN_IN",
    SIGN_OUT: "SIGN_OUT",
    CHANGE_THEME_MODE: "CHANGE_THEME_MODE",
    HANDLE_AUTH_PATH: "HANDLE_AUTH_PATH",
};
