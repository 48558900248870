import React from "react";
import { SnackbarProvider } from "notistack";
import { AnimatePresence } from "framer-motion";

import { Grommet, Box, Layer } from "grommet";

import { DEFAULT_THEME } from "../theme";
import { AppContext, Action, DEFAULT_STATE } from "../context";
import { Routes } from "../routes";
import { isEmptyStr } from "../utils/strings";
import { setSessionStorageValue, getSessionStorageValue } from "../utils/browser";
import { getUsers } from "../services";
import { Login, Register, VerifyingEmail, CreateProfile } from "./auth";
import { createNotificationMessage, NotificationState } from "../utils/notifications";
import { isElectron } from "../utils/electron";

import Main from "./main";

export default function App() {

    const reducer = (state, action) => { //state 변화 정의
        switch (action.type) {
            case Action.LOAD_USERS_DATA:
                return {
                    ...state,
                    users: action.payload.users,
                }
            case Action.SIGN_IN:
                return {
                    ...state,
                    user: action.payload.user,
                }
            case Action.SIGN_OUT:
                return {
                    ...state,
                    user: undefined,
                };
            case Action.CHANGE_THEME_MODE:
                return {
                    ...state,
                    themeMode: action.payload.themeMode,
                }
            case Action.HANDLE_AUTH_PATH:
                return {
                    ...state,
                    authPath: action.payload.path,
                }
            default:
                break;
        }
    };

    const getInitialState = () => {
        const userData = getSessionStorageValue("USER");
        const usersData = getSessionStorageValue("USERS");
        return {
            user: !isEmptyStr(userData) ? JSON.parse(userData) : DEFAULT_STATE.user,
            users: !isEmptyStr(usersData) ? JSON.parse(usersData) : DEFAULT_STATE.users,
            themeMode: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
            openAuth: undefined,
        }
    };

    const [ globalState, dispatch ] = React.useReducer(reducer, getInitialState());

    const contextValue = React.useMemo(
        () => ({
            globalState: globalState,
            signInAction: (payload) => {
                setSessionStorageValue("USER", JSON.stringify(payload.user));
                dispatch({ type: Action.SIGN_IN, payload: payload });
            },
            signOutAction: () => {
                setSessionStorageValue("USER", null);
                dispatch({ type: Action.SIGN_OUT });
            },
            setThemeModeAction: (payload) => {
                dispatch({ type: Action.CHANGE_THEME_MODE, payload: payload });
            },
            handleAuthPath: (payload) => {
                dispatch({ type: Action.HANDLE_AUTH_PATH, payload: { path: payload.path } });
            },
        }),
        [globalState]
    );

    const onAuthModalClose = () => {
        dispatch({ type: Action.HANDLE_AUTH_PATH, payload: { path: undefined } });
    };

    const handleAuthPath = () => {
        switch (globalState.authPath) {
            case Routes.AUTH_PATH.Register:
                return <Register onClose={onAuthModalClose} />;
            case Routes.AUTH_PATH.VerifyingEmail:
                return <VerifyingEmail onClose={onAuthModalClose} />;
            case Routes.AUTH_PATH.CreateProfile:
                return <CreateProfile onClose={onAuthModalClose} />;
            case Routes.AUTH_PATH.Login:
            default:
                return <Login onClose={onAuthModalClose} />;
        }
    };

    React.useEffect(() => {
        window.matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", event => {
                const colorScheme = event.matches ? "dark" : "light";
                dispatch({ type: Action.CHANGE_THEME_MODE, payload: { themeMode: colorScheme } });
            });

        let mounted = true;

        getUsers()
            .then(users => {
                if (mounted) {
                    setSessionStorageValue("USERS", JSON.stringify(users));
                    dispatch({ type: Action.LOAD_USERS_DATA, payload: { users: users } });
                }
            })
            .catch(error => {
                console.error(error);
                createNotificationMessage(NotificationState.FAILED, "사용자 목록을 읽어오는데 실패했습니다.");
            });

        return () => mounted = false;
    }, []);

    return (
        <AppContext.Provider value={contextValue}>
            <Grommet theme={DEFAULT_THEME} themeMode={globalState.themeMode} full>
                { isElectron() && <Box fill={"horizontal"} height={"30px"} className={"titlebar"} /> }
                <Box direction={"row"} pad={"none"} fill>
                    <SnackbarProvider autoHideDuration={2000} maxSnack={5} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                        { globalState.authPath !== undefined &&
                            <Layer animation={"fadeIn"} position={"center"} background={"transparent"}
                                   onEsc={onAuthModalClose}
                                   modal
                                   responsive
                            >
                                <AnimatePresence>
                                    { handleAuthPath() }
                                </AnimatePresence>
                            </Layer>
                        }
                        <Main />
                    </SnackbarProvider>
                </Box>
            </Grommet>
        </AppContext.Provider>
    );

}
