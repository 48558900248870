import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// For Development
// const firebaseConfig = {
//     apiKey: "AIzaSyDWO5H_PYBTfWl_egLc8KPmkIYyf9kCB7Y",
//     authDomain: "aibada-dev.firebaseapp.com",
//     projectId: "aibada-dev",
//     storageBucket: "aibada-dev.appspot.com",
//     messagingSenderId: "1081272266075",
//     appId: "1:1081272266075:web:df621822687b64801c3e7e",
//     measurementId: "G-7PJ0GKRG4G"
// };

// For Deployment
const firebaseConfig = {
    apiKey: "AIzaSyDwhoCMpwT_K2gg5tZbU9YHanr_VCug8OA",
    authDomain: "aibada.firebaseapp.com",
    projectId: "aibada",
    storageBucket: "aibada.appspot.com",
    messagingSenderId: "297893344148",
    appId: "1:297893344148:web:e50c984636d8ca65ccd581",
    measurementId: "G-82KSKQR73N"
  };

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

export const googleAuthProvider = new GoogleAuthProvider();
