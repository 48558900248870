import React from "react";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";

import {
    Layer, Box, Heading, Button, Form, FormField, TextInput,
} from "grommet";

import { LoaderModal } from "../../../../../../components";
import {ModelProvider, ModelType} from "../../../../../../services";
import { AppContext } from "../../../../../../context";
import { strReplace } from "../../../../../../utils/strings";
import { saveTmModel } from "../../../../../../services";

const DEFAULT_TM_MODEL_VALUE = {
    provider: ModelProvider.TeachableMachine,
    url: "",
    type: "",
    name: "",
};

function isUrlValidate(email) {
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g.test(email);
}

export function LoadModelFromUrl({ onCloseFunc }) {

    const { globalState } = React.useContext(AppContext);

    const [ model, setModel ] = React.useState(DEFAULT_TM_MODEL_VALUE);
    const [ labels, setLabels ] = React.useState([]);

    const [ fetchingUrl, setFetchingUrl ] = React.useState(false);
    const [ fetchUrlError, setFetchUrlError ] = React.useState(undefined);

    const [ isStoring, setIsStoring ] = React.useState(false);

    const onClose = () => {
        setModel(DEFAULT_TM_MODEL_VALUE);
        setLabels([]);
        setFetchingUrl(false);
        setFetchUrlError(undefined);
        setIsStoring(false);
        onCloseFunc();
    };

    const save = async () => {
        if (model.name !== undefined && model.type !== undefined) {
            setIsStoring(true);

            const result = await saveTmModel(model, labels, globalState.user.id);
            setIsStoring(false);

            if (result) {
                onClose();
            }
        }
    };

    const onUrlChange = useDebouncedCallback(
        (e) => {
            fetchMetadata(e.target.value);
        }, 500
    );

    const fetchMetadata = (url) => {
        if (isUrlValidate(url)) {
            setFetchingUrl(true);

            axios({
                method: "get",
                url: `${url}metadata.json`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            })
                .then(response => {
                    setFetchUrlError(undefined);
                    const type = strReplace(response.data.packageName, "@teachablemachine/", "");
                    setModel(prev => ({
                        ...prev,
                        type: type === "image" ? ModelType.ImageClassification : type,
                        name: response.data.modelName,
                    }));
                    setLabels(response.data.labels);
                })
                .catch(error => {
                    console.error(error);
                    setFetchUrlError("유효하지 않은 주소입니다.");
                    setModel(prev => ({
                        ...prev,
                        type: "",
                        name: "",
                    }));
                    setLabels(undefined);
                })
                .finally(() => {
                    setFetchingUrl(false);
                });
        }
    }

    return (
        <>
            { fetchingUrl === true && <LoaderModal message={"모델 로딩 중..."} /> }
            { isStoring === true &&  <LoaderModal message={"서버에 저장 중..."} /> }

            <Layer position={"center"}
                   style={{ borderRadius: 24 }}
                   modal
                   responsive
            >
                <Box pad={{ vertical: "medium", horizontal: "large" }}>
                    <Heading level={"3"} margin={{ bottom: "30px" }}>모델 등록</Heading>
                    <Form value={model}
                          validate={"blur"}
                          onChange={(nextValue) => setModel(nextValue)}
                          onReset={() => onClose()}
                          onSubmit={() => save()}
                    >
                        <FormField label={"모델 링크 주소"} name={"url"} margin={{ bottom: "small" }}
                                   validate={[
                                       (value) => {
                                           if (!value || value.length < 1) return "필수 입력 사항입니다.";
                                           else if (!isUrlValidate(value)) return "잘못된 URL 주소입니다.";
                                           else return undefined;
                                       }
                                   ]}
                                   error={fetchUrlError}
                        >
                            <TextInput name={"url"} type={"text"} size={"small"} style={{ width: 320 }}
                                       onChange={onUrlChange}
                            />
                        </FormField>
                        { model && model.name &&
                            <FormField label={"모델 이름"} name={"name"} margin={{ vertical: "small" }}>
                                <TextInput name={"name"} type={"text"} size={"small"} style={{ width: 320 }} disabled />
                            </FormField>
                        }
                        <Box direction={"row"} gap={"small"} justify={"end"} margin={{ top: "medium" }}>
                            <Button secondary size={"small"} label={"취소"} type={"reset"} />
                            <Button primary size={"small"} label={"등록"} type={"submit"} />
                        </Box>
                    </Form>
                </Box>
            </Layer>
        </>
    );

}

export function LoadModelFromLocal() {

    return (
        <></>
    );

}
