import React from "react";
import { motion } from "framer-motion";
import Webcam from "react-webcam";

import {
    ResponsiveContext, Box, Button, Card, Text, Spinner, Grid,
    Layer, Heading, Image, Paragraph, Avatar, Tabs, Tab,
} from "grommet";
import {
    Like, Favorite, Chat, FormClose, Refresh, User, CloudUpload, Video, Camera
} from "grommet-icons";

import { AppContext } from "../../../../context";
import { ModelTypeTag } from "../../components";
import DragDropFile from "../../../../components/form/file";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../../../../config/firebase";
import { createNotificationMessage, NotificationState } from "../../../../utils/notifications";
import { LoaderModal } from "../../../../components";
import { createTfModel } from "../../../../utils/tensorflow";
import { ModelProvider } from "../../../../services";

const FilterButton = ({ label, currentFilter, filter, setFilter }) => (
    <Button size={"small"} label={<Text size={"small"} style={{ fontSize: 13 }}>{label}</Text>}
            primary={currentFilter === filter}
            style={{ padding: "5px 14px" }}
            onClick={() => setFilter(filter)}
    />
);

const COLS = {
    xsmall: 2,
    small: 3,
    medium: 4,
    large: 5,
    xlarge: 6,
};

export default function Home() {

    const { globalState } = React.useContext(AppContext);
    const isLogin = globalState.user !== undefined;
    const size = React.useContext(ResponsiveContext);

    const [ filter, setFilter ] = React.useState("");

    const [ apps, setApps ] = React.useState([]);
    const [ isLoading, setIsLoading ] = React.useState(true);

    const [ selectedApp, setSelectedApp ] = React.useState(undefined);

    const onClickApp = (appId) => {
        const filteredApp = apps.filter(app => app.id === appId);
        if (filteredApp.length > 0) {
            setSelectedApp(filteredApp[0]);
        }
    };

    const loadApps = React.useCallback(async () => {
        let result = {
            success: true,
            payload: [],
        };

        const userId = globalState.user?.id !== undefined ? globalState.user.id : undefined;
        let q;
        if (userId) {
            q = query(
                collection(db, "apps"),
                where("deleted", "==", false),
                where("createdBy", "!=", userId),
                // orderBy("createdBy", "asc"),
                // orderBy("lastModified", "desc"),
            );
        }
        else {
            q = query(
                collection(db, "apps"),
                where("deleted", "==", false),
                orderBy("lastModified", "desc"),
            );
        }

        const appsSnapShot = await getDocs(q);
        appsSnapShot.forEach(documentSnapshot => {
            result.payload.push({
                ...documentSnapshot.data(),
                id: documentSnapshot.id,
            });
        });

        return result;
    }, [globalState.user]);

    React.useEffect(() => {
        let mounted = true;

        loadApps()
            .then(result => {
                if (mounted && result && result.success) {
                    setApps(result.payload);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error(error);
                createNotificationMessage(NotificationState.FAILED, "앱 목록을 읽어오는데 실패했습니다.");
            });

        return () => mounted = false;
    }, [globalState.user, loadApps]);

    return (
        <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{ width: "100%", height: "100%" }}
        >
            { selectedApp !== undefined && <AppView app={selectedApp} onCloseFunc={() => setSelectedApp(undefined)} /> }
            <Box pad={"xsmall"} direction={"column"} gap={"medium"} fill>
                <Box direction={"row"} gap={"xsmall"} align={"center"}>
                    <FilterButton label={"전체"} currentFilter={filter} filter={""} setFilter={setFilter} />
                    <FilterButton label={"최근 등록된"} currentFilter={filter} filter={"recentUploaded"} setFilter={setFilter} />
                    { isLogin && <FilterButton label={"최근 실행한"} currentFilter={filter} filter={"recentExecuted"} setFilter={setFilter} /> }
                    { isLogin && <FilterButton label={"사용자 맞춤"} currentFilter={filter} filter={"userCustomized"} setFilter={setFilter} /> }
                    { isLogin && <FilterButton label={"즐겨찾기"} currentFilter={filter} filter={"userPinned"} setFilter={setFilter} /> }
                </Box>
                { isLoading === true ?
                    <Box align={"center"} justify={"center"} animation={"fadeIn"} fill>
                        <Spinner pad={"xsmall"}
                                 border={[
                                     { side: "all", color: "background-contrast", size: "medium" },
                                     { side: "right", color: "brand", size: "medium" },
                                     { side: "top", color: "brand", size: "medium" },
                                     { side: "left", color: "brand", size: "medium" },
                                 ]}
                        />
                    </Box> :
                    <Grid columns={{ count: COLS[size], size: "auto" }} gap={"small"}>
                        { apps.map((app, index) => (
                            <AppCard key={index} app={app} delay={index * 50} onClickFunc={onClickApp} />
                        ))}
                    </Grid>
                }
            </Box>
        </motion.div>
    );

}

const AppCard = ({ app, delay, onClickFunc }) => {

    const { globalState } = React.useContext(AppContext);
    const user = globalState.users.filter(u => u.id === app.createdBy)[0];

    return (
        <Card className={"card hover-zoom-background"}
              animation={{ type: "fadeIn", delay: delay }}
              style={{ position: "relative" }}
              onClick={() => onClickFunc(app.id)}>
            <Box background={{ image: `url(${app.coverImage})`, position: "center" }} fill className={"cover-image"} />
            <Box style={{ position: "absolute", left: 0, top: 0}}
                 fill pad={"medium"} background={"rgb(0,0,0,0.75)"}
            >
                <Box gap={"medium"} flex margin={{ top: "small" }}>
                    <Box gap={"xsmall"} align={"start"}>
                        <Text weight={"bold"} style={{ marginTop: -1 }}>
                            { app.name }
                        </Text>
                        <ModelTypeTag provider={app.model.provider} type={app.model.type} />
                    </Box>
                    <Box>
                        <Text size={"xsmall"} truncate>
                            { app.description ? app.description : "모델에 대한 설명이 없습니다." }
                        </Text>
                    </Box>
                </Box>
                <Box direction={"row"} gap={"xsmall"} align={"center"} fill={"horizontal"} justify={"start"}>
                    { user.photoURL ?
                        <Avatar src={user.photoURL} size={"small"} border={{ size: "xsmall", color: "transparent" }} /> :
                        <Avatar size={"small"} border={{ size: "xsmall", color: "brand" }}>
                            <User size={"12px"} />
                        </Avatar>
                    }
                    <Text size={"xsmall"} truncate>
                        { user.name }
                    </Text>
                </Box>
            </Box>
        </Card>
    );
};

const AppView = ({ app, onCloseFunc }) => {

    const { globalState } = React.useContext(AppContext);
    const user = globalState.users.filter(u => u.id === app.createdBy)[0];

    const [ tabIndex, setTabIndex ] = React.useState(0);

    const [ testImage, setTestImage ] = React.useState(undefined);
    const [ isTesting, setIsTesting ] = React.useState(false);
    const [ testResult, setTestResult ] = React.useState(undefined);

    const webcamRef = React.useRef(null);

    React.useEffect(() => {
        if (testImage !== undefined) {
            const analyze = async () => {
                setIsTesting(true);
                createTfModel(app.model, app.labels)
                    .then(tfModel => {
                        return tfModel.predict(
                            app.model.provider === ModelProvider.Tensorflow ? testImage
                                : document.getElementById("preview")
                        );
                    })
                    .then(result => {
                        let maxValue = 0, label = undefined;
                        result.forEach(r => {
                            if (r.value > maxValue) {
                                maxValue = r.value;
                                label = r.label;
                            }
                        });
                        const finalResult = app.labels.filter(l => l.name === label)[0];
                        setTestResult(finalResult.description);
                    })
                    .catch(error => {
                        console.error(error);
                        createNotificationMessage(NotificationState.FAILED, "이미지를 분석하는데 실패했습니다.");
                    })
                    .finally(() => setIsTesting(false));
            };
            analyze().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testImage]);

    const handleFile = (file) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            setTestImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const capture = React.useCallback(() => {
        const image = webcamRef.current?.getScreenshot();
        if (image) {
            setTestImage(image);
        }
    }, [webcamRef]);

    return (
        <>
            { isTesting === true &&  <LoaderModal message={"이미지 분석 중..."} /> }
            <Layer position={"center"}
                   style={{ borderRadius: 24 }}
                   modal
                   responsive
            >
                <Box pad={{ vertical: "medium", horizontal: "large" }} width={"1024px"} height={"large"}>
                    <Box style={{ position: "absolute", top: 36, right: 36 }}>
                        <Button icon={<FormClose />} onClick={() => onCloseFunc()} style={{ borderRadius: 14 }} />
                    </Box>
                    <Grid rows={["70px", "auto", "84px"]} fill gap={"medium"}>
                        <Box>
                            <Heading level={"3"} margin={{ bottom: "small" }}>
                                { app.name }
                            </Heading>
                            <Box align={"start"}>
                                <ModelTypeTag provider={app.model.provider} type={app.model.type} />
                            </Box>
                        </Box>
                        <Grid columns={["360px", "auto"]} gap={"large"} fill>
                            <Box gap={"small"} flex>
                                <Box round={"small"} overflow={"hidden"}>
                                    <Image src={app.coverImage} fit={"cover"} />
                                </Box>
                                <Box>
                                    <Paragraph size={"xsmall"}>
                                        { app.description }
                                    </Paragraph>
                                </Box>
                            </Box>
                            <Box gap={"medium"} flex>
                                <Tabs activeIndex={tabIndex} onActive={nextIndex => {
                                    setTabIndex(nextIndex);
                                    setTestImage(undefined);
                                    setTestResult(undefined);
                                }}
                                      margin={{ top: "-8px" }}
                                >
                                    <Tab icon={<CloudUpload size={"18px"} />}
                                         title={
                                             <Text size={"small"} weight={tabIndex === 0 ? "bold" : "normal"}>
                                                 이미지 업로드
                                             </Text>
                                         }
                                    ></Tab>
                                    <Tab icon={<Video size={"18px"} />}
                                         title={
                                             <Text size={"small"} weight={tabIndex === 1 ? "bold" : "normal"}>
                                                 웹캠 캡쳐
                                             </Text>
                                         }
                                    ></Tab>
                                </Tabs>
                                { testImage === undefined ?
                                    <Box width={"520px"} height={"465px"}>
                                        { tabIndex === 0 ?
                                            <DragDropFile width={"520px"} height={"463px"} handleFile={handleFile} /> :
                                            <Box fill round={"16px"} width={"520px"} height={"465px"}
                                                 border={{ width: "1px", style: "dashed", side: "all" }}
                                                 pad={"xsmall"}
                                                 className={"webcam-container"}
                                            >
                                                <Webcam ref={webcamRef}
                                                        audio={false}
                                                        width={506} height={451}
                                                        screenshotFormat={"image/jpeg"}
                                                        videoConstraints={{
                                                            width: 506,
                                                            height: 451,
                                                        }}
                                                        style={{ borderRadius: 12 }}
                                                />
                                                <Box fill align={"center"} justify={"center"} className={"camera-button-overlay"}>
                                                    <Box round={"medium"} align={"center"} justify={"center"}
                                                         border={{ size: "medium", color: "brand" }}
                                                         width={"60px"} height={"60px"}
                                                         onClick={capture}
                                                    >
                                                        <Camera />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                    </Box> :
                                    <Box style={{ position: "relative" }} width={"520px"} height={"465px"}
                                         border={{ width: "1px", style: "solid", side: "all" }}
                                         round={"small"} overflow={"hidden"}
                                    >
                                        <Image id={"preview"} src={testImage} fit={"cover"} />
                                        { testResult !== undefined &&
                                            <Box fill overflow={"auto"}
                                                 classsName={"hide-scrollbar"} pad={{ horizontal: "16px" }}
                                                 style={{ position: "absolute", top: 0, left: 0, backgroundColor: "rgb(0,0,0,0.85)" }}
                                            >
                                                <div style={{ fontSize: 14, color: "white" }}
                                                     dangerouslySetInnerHTML={{ __html: testResult }}
                                                />
                                            </Box>
                                        }
                                    </Box>
                                }
                            </Box>
                        </Grid>
                        <Box fill={"horizontal"} justify={"between"} direction={"row"}>
                            <Box gap={"16px"}>
                                <Box direction={"row"} gap={"xsmall"} align={"center"} fill={"horizontal"} justify={"start"}>
                                    { user.photoURL ?
                                        <Avatar src={user.photoURL} size={"32px"} border={{ size: "xsmall", color: "transparent" }} /> :
                                        <Avatar size={"32px"} border={{ size: "xsmall", color: "brand" }}>
                                            <User size={"14px"} />
                                        </Avatar>
                                    }
                                    <Text size={"small"} truncate>
                                        { user.name }
                                    </Text>
                                </Box>
                                <Box direction={"row"} gap={"small"} margin={{ bottom: "small" }}>
                                    <Button primary size={"small"} pad={{ horizontal: "13px", vertical: "3px" }}
                                            icon={<Like size={"10px"} />}
                                            label={<Text size={"xsmall"}>{ 0 }</Text>}
                                    />
                                    <Button primary size={"small"} pad={{ horizontal: "13px", vertical: "3px" }}
                                            icon={<Favorite size={"10px"} />}
                                            label={<Text size={"xsmall"}>{ 0 }</Text>}
                                    />
                                    <Button primary size={"small"} pad={{ horizontal: "13px", vertical: "3px" }}
                                            icon={<Chat size={"10px"} />}
                                            label={<Text size={"xsmall"}>{ 0 }</Text>}
                                    />
                                </Box>
                            </Box>
                            <Box justify={"end"} margin={{ bottom: "small" }}>
                                { testResult !== undefined &&
                                    <Button primary size={"small"}
                                            icon={<Refresh size={"14px"} />}
                                            label={"다시 하기"}
                                            onClick={() => {
                                                setTestImage(undefined);
                                                setTestResult(undefined);
                                            }}
                                    />
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </Layer>
        </>
    );
};
