import { enqueueSnackbar } from "notistack";
import { Box, Text } from "grommet";
import { StatusGood, StatusInfo, StatusWarning, StatusCritical } from "grommet-icons";

export const NotificationState = {
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    FAILED: "failed",
};

export const createNotificationMessage = (state, message) => {
    let  icon;
    switch (state) {
        case NotificationState.SUCCESS:
            icon = <StatusGood size={"18px"} color={"status-ok"} />
            break;
        case NotificationState.WARNING:
            icon = <StatusWarning size={"18px"} color={"status-waring"} />
            break;
        case NotificationState.FAILED:
            icon = <StatusCritical size={"18px"} color={"status-critical"} />
            break;
        case NotificationState.INFO:
        default:
            icon = <StatusInfo size={"18px"} color={"status-info"} />
            break;
    }
    return enqueueSnackbar((
        <Box direction={"row"} gap={"small"} align={"center"}>
            { icon }
            <Box width={"290px"} wrap>
                <Text size={"small"}>
                    { message }
                </Text>
            </Box>
        </Box>
    ), { preventDuplicate: true });
};
