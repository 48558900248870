import React from "react";

import { Box, Text } from "grommet";
import { ModelProvider, TfModelType, TmModelType } from "../../../../../services";

export default function ModelTypeTag({ provider, type, primary }) {

    let modelType = { color: undefined, plainIcon: undefined, icon: undefined, label: undefined };

    switch (provider) {
        case ModelProvider.Tensorflow:
            modelType = TfModelType.valueOf(type);
            break;
        case ModelProvider.TeachableMachine:
            modelType = TmModelType.valueOf(type);
            break;
        default:
            break;
    }

    return modelType ? (
        <Box direction={"row"} gap={"xsmall"} align={"center"} round
             background={primary ? modelType.color : "transparent"}
             pad={{ horizontal: primary ? "small" : "none", vertical: primary ? "2px" : "none" }}
        >
            { primary ? modelType.plainIcon : modelType.icon }
            <Text size={"xsmall"} weight={"bolder"} color={primary ? "text" : modelType.color}>
                { `${modelType.label}` }
            </Text>
            <Text size={"xsmall"}>
                { `by ${ModelProvider.keyOf(provider)}` }
            </Text>
        </Box>
    ) : <></>;

}
