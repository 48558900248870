import React from "react";

import {
    Layer, Box, Heading, Form, FormField, TextInput, Button, Text, Select,
} from "grommet";

import { AppContext } from "../../../../../../context";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../../../../config/firebase";
import { createNotificationMessage, NotificationState } from "../../../../../../utils/notifications";

const DEFAULT_VALUE = {
    name: "",
    model: undefined,
};

export default function CreateApp({ onCloseFunc, onOpenSetup }) {

    const { globalState } = React.useContext(AppContext);

    const [ value, setValue ] = React.useState(DEFAULT_VALUE);
    const [ models, setModels ] = React.useState([]);

    const onClose = () => {
        setValue(DEFAULT_VALUE);
        onCloseFunc();
    };

    const onSubmit = (value) => {
        onClose();
        onOpenSetup(value);
    };

    const loadModels = React.useCallback(async () => {
        let result = {
            success: true,
            payload: [],
        };

        const q = query(
            collection(db, "models"),
            where("deleted", "==", false),
            where("createdBy", "==", globalState.user.id),
            orderBy("lastModified", "desc"),
        );

        const modelsSnapShot = await getDocs(q);
        modelsSnapShot.forEach(documentSnapshot => {
            result.payload.push({
                ...documentSnapshot.data(),
                id: documentSnapshot.id,
            });
        });

        return result;
    }, [globalState.user.id]);

    React.useEffect(() => {
        let mounted = true;

        loadModels()
            .then(result => {
                if (mounted && result && result.success) {
                    setModels(result.payload);
                }
            })
            .catch(error => {
                console.error(error);
                createNotificationMessage(NotificationState.FAILED, "모델 목록을 읽어오는데 실패했습니다.");
            });

        return () => mounted = false;
    }, [globalState.user.id, loadModels]);

    return (
        <Layer position={"center"}
               style={{ borderRadius: 24 }}
               modal
               responsive
        >
            <Box pad={{ vertical: "medium", horizontal: "large" }}>
                <Heading level={"3"} margin={{ bottom: "30px" }}>앱 등록</Heading>
                <Form value={value}
                      validate={"blur"}
                      onChange={(nextValue) => setValue(nextValue)}
                      onSubmit={(event) => onSubmit(event.value)}
                >
                    <FormField label={"앱 이름"} name={"name"} margin={{ bottom: "medium" }}
                               validate={[
                                   (value) => {
                                       if (!value || value.length < 1) return "필수 입력 사항입니다.";
                                       else return undefined;
                                   }
                               ]}
                    >
                        <TextInput name={"name"} type={"text"} size={"small"} style={{ minWidth: 320 }} />
                    </FormField>
                    <FormField label={"적용할 모델"} name={"model"} margin={{ bottom: "medium" }}
                               validate={[
                                   (value) => {
                                       if (value === undefined) return "필수 입력 사항입니다.";
                                       else return undefined;
                                   }
                               ]}
                    >
                        <Select size={"small"}
                                name={"model"}
                                options={models}
                                labelKey={"name"}
                        />
                    </FormField>
                    <Box direction={"row"} gap={"small"} justify={"end"} margin={{ bottom: "small" }}>
                        <Button secondary size={"small"}
                                label={"취소"}
                                onClick={() => onClose()}
                        />
                        <Button primary size={"small"} type={"submit"}
                                label={<Text size={"small"} color={"text"} weight={"bold"}>등록</Text>}
                        />
                    </Box>
                </Form>
            </Box>
        </Layer>
    );

}
