import React from "react";
import { HashRouter, Routes as RouterRoutes, Route, Navigate } from "react-router-dom";

import { AnimatePresence } from "framer-motion";
import { Main as MainPage, Box } from "grommet";

import { TopBar, SideBar } from "./components";

import { Routes } from "../../routes";
import { Home, AppManagement, ModelManagement, Profile, Settings } from "./pages";

export default function Main() {

    return (
        <HashRouter>
            <MainPage fill direction={"row"} overflow={"hidden"}>
                <SideBar />
                <Box fill pad={"24px"}>
                    <TopBar />
                    <Box fill>
                        <AnimatePresence>
                            <RouterRoutes>
                                <Route path={Routes.PATH.Home} element={<Home />} />
                                <Route path={Routes.PATH.AppManagement} element={<AppManagement />} />
                                <Route path={Routes.PATH.ModelManagement} element={<ModelManagement />} />
                                <Route path={Routes.PATH.Profile} element={<Profile />} />
                                <Route path={Routes.PATH.Settings} element={<Settings />} />
                                <Route path={Routes.PATH.All} element={<Navigate to={Routes.PATH.Home} replace />} />
                            </RouterRoutes>
                        </AnimatePresence>
                    </Box>
                </Box>
            </MainPage>
        </HashRouter>
    );

}
