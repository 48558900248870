import React from "react";
import { motion } from "framer-motion";

import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import {
    ResponsiveContext, Box, Spinner, Grid, Card, Button, Text,
} from "grommet";

import { AppContext } from "../../../../../context";
import { db } from "../../../../../config/firebase";
import { createNotificationMessage, NotificationState } from "../../../../../utils/notifications";
import { CreateApp, ModelTypeTag, SetupApp } from "../../../components";

const COLS = {
    xsmall: 2,
    small: 3,
    medium: 4,
    large: 5,
    xlarge: 6,
};

export default function AppManagement() {

    const { globalState } = React.useContext(AppContext);
    const size = React.useContext(ResponsiveContext);

    const [ apps, setApps ] = React.useState([]);
    const [ isLoading, setIsLoading ] = React.useState(true);

    const [ appInputValue, setAppInputValue ] = React.useState(undefined);
    const [ appRegistrationLayerOpen, setAppRegistrationLayerOpen ] = React.useState(false);
    const openAppRegistrationLayer = () => setAppRegistrationLayerOpen(true);
    const closeAppRegistrationLayer = () => {
        setAppRegistrationLayerOpen(false);
    };

    const [ appSetupLayerOpen, setAppSetupLayerOpen ] = React.useState(false);
    const openAppSetupLayer = (inputValue) => {
        setAppInputValue(inputValue);
        setAppSetupLayerOpen(true);
    };
    const closeAppSetupLayer = () => {
        setAppSetupLayerOpen(false);
    };

    const loadApps = React.useCallback(async () => {
        let result = {
            success: true,
            payload: [],
        };

        const q = query(
            collection(db, "apps"),
            where("deleted", "==", false),
            where("createdBy", "==", globalState.user.id),
            orderBy("lastModified", "desc"),
        );

        const appsSnapShot = await getDocs(q);
        appsSnapShot.forEach(documentSnapshot => {
            result.payload.push({
                ...documentSnapshot.data(),
                id: documentSnapshot.id,
            });
        });

        return result;
    }, [globalState.user.id]);

    React.useEffect(() => {
        let mounted = true;

        loadApps()
            .then(result => {
                if (mounted && result && result.success) {
                    setApps(result.payload);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error(error);
                createNotificationMessage(NotificationState.FAILED, "앱 목록을 읽어오는데 실패했습니다.");
            });

        return () => mounted = false;
    }, [globalState.user.id, loadApps]);

    React.useEffect(() => {
        let mounted = true;

        if (appSetupLayerOpen === false) {
            loadApps()
                .then(result => {
                    if (mounted && result && result.success) {
                        setApps(result.payload);
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                    createNotificationMessage(NotificationState.FAILED, "앱 목록을 읽어오는데 실패했습니다.");
                });
        }

        return () => mounted = false;
    }, [appSetupLayerOpen, loadApps]);

    return (
        <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{ width: "100%", height: "100%" }}
        >
            { appRegistrationLayerOpen === true && <CreateApp onOpenSetup={openAppSetupLayer} onCloseFunc={closeAppRegistrationLayer} /> }
            { appSetupLayerOpen === true && <SetupApp inputValue={appInputValue} onCloseFunc={closeAppSetupLayer} /> }

            { isLoading === true ?
                <Box align={"center"} justify={"center"} animation={"fadeIn"} fill>
                    <Spinner pad={"xsmall"}
                             border={[
                                 { side: "all", color: "background-contrast", size: "medium" },
                                 { side: "right", color: "brand", size: "medium" },
                                 { side: "top", color: "brand", size: "medium" },
                                 { side: "left", color: "brand", size: "medium" },
                             ]}
                    />
                </Box> :
                <Grid columns={{ count: COLS[size], size: "auto" }} gap={"small"}>
                    { apps.map((app, index) => (
                        <AppCard key={index} app={app} delay={index * 50} />
                    ))}
                    <Card className={"card cursor-default"} pad={"medium"}
                          animation={{ type: "fadeIn", delay: apps.length * 50 }}
                          onClick={() => {}}
                    >
                        <Box fill align={"center"} justify={"center"} gap={"medium"}>
                            <Box align={"center"} gap={"16px"}>
                                <Box gap={"xsmall"}>
                                    { apps.length <= 0 &&
                                        <Text size={"xsmall"} alignSelf={"center"}>
                                            등록된 앱이 없습니다.
                                        </Text>
                                    }
                                    <Text size={"xsmall"}>
                                        새로운 앱을 등록 하시겠습니까?
                                    </Text>
                                </Box>
                                <Button size={"small"} primary
                                        label={<Text size={"xsmall"}>등록하기</Text>}
                                        onClick={() => openAppRegistrationLayer()}
                                />
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            }
        </motion.div>
    );

}

const AppCard = ({ app, delay }) => (
    <Card className={"card hover-zoom-background"}
          animation={{ type: "fadeIn", delay: delay }}
          style={{ position: "relative" }}
          onClick={() => {}}>
        <Box background={{ image: `url(${app.coverImage})`, position: "center" }} fill className={"cover-image"} />
        <Box style={{ position: "absolute", left: 0, top: 0}}
             fill pad={"medium"} background={"rgb(0,0,0,0.75)"}
        >
            <Box gap={"medium"} flex margin={{ top: "small" }}>
                <Box gap={"xsmall"} align={"start"}>
                    <Text weight={"bold"} style={{ marginTop: -1 }}>
                        { app.name }
                    </Text>
                    <ModelTypeTag provider={app.model.provider} type={app.model.type} />
                </Box>
                <Box>
                    <Text size={"xsmall"} truncate>
                        { app.description ? app.description : "모델에 대한 설명이 없습니다." }
                    </Text>
                </Box>
            </Box>
            <Box align={"end"} fill={"horizontal"}>
                <Button secondary size={"small"}
                        label={<Text size={"xsmall"}>상세 보기</Text>}
                />
            </Box>
        </Box>
    </Card>
);
