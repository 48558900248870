import React from "react";
import { motion } from "framer-motion";

import { Box, Card, Button, Form, FormField, Heading, Image, Text, TextInput } from "grommet";
import { UserAdd, User } from "grommet-icons";
import imageCompression from "browser-image-compression";

import { AppContext } from "../../../context";
import { LoaderModal } from "../../../components";
import { Routes } from "../../../routes";
import { FILE_RESIZE_OPTION } from "../../../config/file";
import { createProfile } from "../../../services";
import { createNotificationMessage, NotificationState } from "../../../utils/notifications";

const DEFAULT_VALUE = {
    name: "",
};

export default function CreateProfile({ onClose }) {

    const { globalState, handleAuthPath, signInAction } = React.useContext(AppContext);
    const isDarkMode = globalState.themeMode === "dark";

    const [ value, setValue ] = React.useState(DEFAULT_VALUE);
    const [ profileImage, setProfileImage ] = React.useState(undefined);
    const fileRef = React.useRef(null);

    const [ isInProgress, setInProgress ] = React.useState(false);

    const onSelectFile = (e) => {
        setInProgress(true);
        imageCompression(e.target.files[0], FILE_RESIZE_OPTION)
            .then(compressedImage => {
                let reader = new FileReader();
                reader.onloadend = () => {
                    setProfileImage({
                        blob: compressedImage,
                        buffer: reader.result,
                    });
                    setInProgress(false);
                };
                reader.readAsDataURL(compressedImage);
            })
            .catch(error => {
                console.error(error);
                setInProgress(false);
            });
    };

    const submit = async (value) => {
        setInProgress(true);
        const user = await createProfile(profileImage, value.name)
            .catch(error => {
                console.error(error);
                setInProgress(false);
                createNotificationMessage(NotificationState.FAILED, "프로필 생성에 실패했습니다.");
            });

        setInProgress(false);

        if (user) {
            signInAction({ user: user });
            onClose();
            handleAuthPath({ path: undefined });
            createNotificationMessage(NotificationState.SUCCESS, `${user.name}님 환영합니다.`);
        }
        else {
            createNotificationMessage(NotificationState.FAILED, "프로필 생성에 실패했습니다.");
        }
    };

    return (
        <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{ width: "100%", height: "100%" }}
        >
            { isInProgress === true &&  <LoaderModal message={"처리 중..."} /> }
            <Card width={"360px"} pad={"large"} gap={"medium"} round={"medium"}>
                <Box direction={"row"} gap={"medium"}>
                    <UserAdd size={"36px"} style={{ alignSelf: "center" }} />
                    <Heading level={"3"} style={{ fontWeight: 900, paddingTop: 3 }}>
                        { Routes.TITLES.CreateProfile }
                    </Heading>
                </Box>
                <Box>
                    <Box>
                        <Text size={"xsmall"}>프로필 사진</Text>
                    </Box>
                    <Box pad={{ vertical: "small" }} align={"center"}>
                        <Box width={"160px"} height={"160px"} align={"center"} justify={"center"} round={"100%"} overflow={"hidden"}
                             border={{ color: "border", width: "1px" }} className={"cover-image-element"}
                             style={{ position: "relative" }}
                        >
                            { profileImage === undefined ? <User size={"36px"} /> : (
                                <Image src={profileImage.buffer} fit={"cover"} width={"160px"} height={"160px"} />
                            )}
                            <Box width={"160px"} height={"36px"} background={{ color: "background", opacity: "75%" }}
                                 align={"center"} justify={"center"} pad={{ bottom: "3px" }}
                                 style={{ position: "absolute", left: "50%", bottom: 0, transform: "translateX(-50%)" }}
                                 className={"disabled-focus"}
                                 hoverIndicator={{ background: { color: "background", opacity: "100%" } }}
                                 onClick={() => fileRef.current.click()}
                            >
                                <Text size={"small"}>변경하기</Text>
                            </Box>
                            <input ref={fileRef} type={"file"} accept={"image/*"} hidden
                                   onChange={onSelectFile}
                            />
                        </Box>
                    </Box>
                </Box>
                <Form value={value}
                      validate={"blur"}
                      onChange={(nextValue) => setValue(nextValue)}
                      onSubmit={(event) => submit(event.value)}
                >
                    <FormField label={"이름"} htmlFor={"name"} name={"name"}
                               validate={[
                                   (name) => {
                                       if (!name || name.length < 1) return "필수 입력 사항입니다.";
                                       else return undefined;
                                   }
                               ]}
                    >
                        <TextInput id={"name"} name={"name"} type={"text"} size={"small"} />
                    </FormField>
                    <Box direction={"row"} gap={"small"} justify={"end"} margin={{ top: "medium" }}>
                        <Button primary={isDarkMode} secondary={!isDarkMode} type={"submit"} size={"small"}
                                label={<Text color={"text"} size={"small"}>생성완료</Text>}
                        />
                    </Box>
                </Form>
            </Card>
        </motion.div>
    );

}
