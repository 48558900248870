import React from "react";

import { Box, Text, Anchor } from "grommet";

import { AppContext } from "../../../context";

export default function DragDropFile({ width = "100%", height = "100%", handleFile, multiple = false }) {

    const [ dragActive, setDragActive ] = React.useState(false);
    const inputRef = React.useRef(null);

    const { globalState } = React.useContext(AppContext);

    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile && (multiple ? handleFile(e.dataTransfer.files) : handleFile(e.dataTransfer.files[0]));
        }
    };

    const handleChange = function(e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile && (multiple ? handleFile(e.target.files) : handleFile(e.target.files[0]));
        }
    };

    const handleButtonClick = function(e) {
        e.preventDefault();
        if(e.target !== inputRef.current) {
            inputRef.current?.click();
        }
    }

    return (
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}
              style={{ width: width, height: height }}
        >
            <input ref={inputRef} type="file" id="input-file-upload" multiple={multiple} onChange={handleChange} />
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? `drag-active-${globalState.themeMode}` : `${globalState.themeMode}` }>
                <Box gap={"small"}>
                    <Text size={"small"}>
                        파일을 드래그하여 놓으시거나,
                    </Text>
                    <Anchor size={"small"} color={"text-weak"} label={"여기를 눌러 선택하세요."} onClick={handleButtonClick} />
                </Box>
            </label>
            { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
        </form>
    );
};
