export function Routes() {}

Routes.getTitleByPath = (path) => {
    return Routes.TITLES[Object.keys(Routes.PATH).find((key) => Routes.PATH[key] === path)];
};

Routes.PATH = {
    Home: "/home",
    AppManagement: "/app/management",
    ModelManagement: "/model/management",
    Profile: "/profile",
    Settings: "/settings",
    All: "*",
};

Routes.AUTH_PATH = {
    Login: "/login",
    Register: "/register",
    VerifyingEmail: "/verifyingEmail",
    CreateProfile: "/createProfile",
};

Routes.TITLES = {
    Login: "로그인",
    Register: "회원가입",
    VerifyingEmail: "이메일 인증",
    CreateProfile: "프로필 생성",
    Home: "홈",
    AppManagement: "앱 관리",
    ModelManagement: "모델 관리",
    Profile: "프로필",
    Settings: "설정",
};
