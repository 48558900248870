import React from "react";

import {
    Layer, Box, Heading, Button, Form, FormField, RadioButtonGroup, Text, TextInput
} from "grommet";

import { TfModelType } from "../../../../../../services";

const MODEL_TYPE_OPTIONS = Object.entries(TfModelType)
    .filter(([key, value]) => typeof key === "string" && typeof value !== "function")
    .map(([key, value]) => ({
        key: key,
        label: <Text size={"small"}>{value.label}</Text>,
        value: value.value,
    }))
;

const DEFAULT_VALUE = {
    modelType: MODEL_TYPE_OPTIONS[0].value,
    modelName: "",
};

export default function CreateModel({ setCreationModelInfo, onCloseFunc }) {

    const [ value, setValue ] = React.useState(DEFAULT_VALUE);

    const onClose = () => {
        setValue(DEFAULT_VALUE);
        onCloseFunc();
    };

    const onSubmit = (value) => {
        setCreationModelInfo(value);
        onClose();
    };

    return (
        <Layer position={"center"}
               style={{ borderRadius: 24 }}
               modal
               responsive
        >
            <Box pad={{ vertical: "medium", horizontal: "large" }}>
                <Heading level={"3"} margin={{ bottom: "30px" }}>모델 생성</Heading>
                <Form value={value}
                      validate={"blur"}
                      onChange={(nextValue) => setValue(nextValue)}
                      onSubmit={(event) => onSubmit(event.value)}
                >
                    <Box margin={{ bottom: "small" }} pad={{ right: "medium" }} gap={"xsmall"}>
                        <Text size={"xsmall"} weight={"bold"}>
                            모델 타입
                        </Text>
                        <RadioButtonGroup name={"modelType"}
                                          direction={"row"}
                                          gap={"small"}
                                          options={MODEL_TYPE_OPTIONS}
                                          style={{ margin: 0 }}
                        />
                    </Box>
                    <FormField label={"모델 이름"} name={"modelName"} margin={{ bottom: "medium" }}
                               validate={[
                                   (value) => {
                                       if (!value || value.length < 1) return "필수 입력 사항입니다.";
                                       else return undefined;
                                   }
                               ]}
                    >
                        <TextInput name={"modelName"} type={"text"} size={"small"} style={{ minWidth: 320 }} />
                    </FormField>
                    <Box direction={"row"} gap={"small"} justify={"end"} margin={{ bottom: "small" }}>
                        <Button secondary size={"small"}
                                label={"취소"}
                                onClick={() => onClose()}
                        />
                        <Button primary size={"small"} type={"submit"}
                                label={<Text size={"small"} color={"text"} weight={"bold"}>생성</Text>}
                        />
                    </Box>
                </Form>
            </Box>
        </Layer>
    );

}
