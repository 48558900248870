import React from "react";
import { motion } from "framer-motion";

import { collection, query, where, orderBy, getDocs } from "firebase/firestore";

import { Box, ResponsiveContext, Card, Grid, Text, Button, Spinner } from "grommet";
import { Link, Storage } from "grommet-icons";

import { db } from "../../../../../config/firebase";
import { AppContext } from "../../../../../context";
import { createNotificationMessage, NotificationState } from "../../../../../utils/notifications";
import { CreateModel, LearningModel, LoadModelFromUrl, ModelTypeTag } from "../../../components";

const COLS = {
    xsmall: 2,
    small: 3,
    medium: 4,
    large: 5,
    xlarge: 6,
};

export default function ModelManagement() {

    const { globalState } = React.useContext(AppContext);
    const size = React.useContext(ResponsiveContext);

    const [ models, setModels ] = React.useState([]);
    const [ isLoading, setIsLoading ] = React.useState(true);

    const [ tfModelLayerOpen, setTfModelLayerOpen ] = React.useState(false);
    const openTfModelLayer = () => setTfModelLayerOpen(true);
    const closeTfModelLayer = () => {
        setTfModelLayerOpen(false);
    };

    const [ tfModelCreationInfo, setTfModelCreationInfo ] = React.useState(undefined);
    const closeTfLearningModelLayer = () => {
        setTfModelCreationInfo(undefined);
    };

    const [ tmModelLayerOpen, setTmModelLayerOpen ] = React.useState(false);
    const openTmModelLayer = () => setTmModelLayerOpen(true);
    const closeTmModelLayer = () => {
        setTmModelLayerOpen(false);
    };

    const loadModels = React.useCallback(async () => {
        let result = {
            success: true,
            payload: [],
        };

        const q = query(
            collection(db, "models"),
            where("deleted", "==", false),
            where("createdBy", "==", globalState.user.id),
            orderBy("lastModified", "desc"),
        );

        const modelsSnapShot = await getDocs(q);
        modelsSnapShot.forEach(documentSnapshot => {
            result.payload.push({
                ...documentSnapshot.data(),
                id: documentSnapshot.id,
            });
        });

        return result;
    }, [globalState.user.id]);

    React.useEffect(() => {
        let mounted = true;

        loadModels()
            .then(result => {
                if (mounted && result && result.success) {
                    setModels(result.payload);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error(error);
                createNotificationMessage(NotificationState.FAILED, "모델 목록을 읽어오는데 실패했습니다.");
            });

        return () => mounted = false;
    }, [globalState.user.id, loadModels]);

    React.useEffect(() => {
        let mounted = true;

        if (tfModelCreationInfo === false || tmModelLayerOpen === false) {
            loadModels()
                .then(result => {
                    if (mounted && result && result.success) {
                        setModels(result.payload);
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                    createNotificationMessage(NotificationState.FAILED, "모델 목록을 읽어오는데 실패했습니다.");
                });
        }

        return () => mounted = false;
    }, [loadModels, tfModelCreationInfo, tmModelLayerOpen]);

    return (
        <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{ width: "100%", height: "100%" }}
        >
            { tfModelLayerOpen === true && <CreateModel setCreationModelInfo={setTfModelCreationInfo} onCloseFunc={closeTfModelLayer} /> }
            { tfModelCreationInfo !== undefined && <LearningModel modelInfo={tfModelCreationInfo} onCloseFunc={closeTfLearningModelLayer} />}
            { tmModelLayerOpen === true && <LoadModelFromUrl onCloseFunc={closeTmModelLayer} /> }
            { isLoading === true ?
                <Box align={"center"} justify={"center"} animation={"fadeIn"} fill>
                    <Spinner pad={"xsmall"}
                             border={[
                                 { side: "all", color: "background-contrast", size: "medium" },
                                 { side: "right", color: "brand", size: "medium" },
                                 { side: "top", color: "brand", size: "medium" },
                                 { side: "left", color: "brand", size: "medium" },
                             ]}
                    />
                </Box> :
                <Grid columns={{ count: COLS[size], size: "auto" }} gap={"small"}>
                    { models.map((model, index) => (
                        <ModelCard key={index} model={model} delay={index * 50} />
                    ))}
                    <Card className={"card cursor-default"} pad={"medium"}
                          animation={{ type: "fadeIn", delay: models.length * 50 }}
                          onClick={() => {}}
                    >
                        <Box fill align={"center"} justify={"center"} gap={"medium"}>
                            <Box align={"center"} gap={"16px"}>
                                <Text size={"xsmall"}>
                                    새로운 모델을 생성 하시겠습니까?
                                </Text>
                                <Button size={"small"} primary
                                        label={<Text size={"xsmall"}>생성하기</Text>}
                                        onClick={() => openTfModelLayer()}
                                />
                            </Box>
                            <Box width={"80%"} height={"1px"} margin={{ horizontal: "medium" }} background={"background-contrast"} />
                            <Box gap={"16px"}>
                                <Box align={"center"} gap={"xxxsmall"}>
                                    <Text size={"xsmall"}>
                                        모델 링크 주소로 불러오거나,
                                    </Text>
                                    <Text size={"xsmall"}>
                                        로컬에 저장된 모델을 불러올 수 있습니다.
                                    </Text>
                                </Box>
                                <Box direction={"row"} gap={"small"} align={"center"} justify={"center"}>
                                    <Button size={"small"} primary plain={false}
                                            style={{ width: 48, height: 36, textAlign: "-webkit-center" }}
                                            icon={<Link size={"14px"} />}
                                            onClick={() => openTmModelLayer()}
                                    />
                                    <Button size={"small"} secondary plain={false}
                                            style={{ width: 48, height: 36, textAlign: "-webkit-center" }}
                                            icon={<Storage size={"14px"} />}
                                            onClick={() => {}}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            }
        </motion.div>
    );

}

const ModelCard = ({ model, delay }) => (
    <Card className={"card cursor-default"} pad={"medium"}
          animation={{ type: "fadeIn", delay: delay }}
          onClick={() => {}}
    >
        <Box fill align={"start"} justify={"center"} gap={"medium"}>
            <Box gap={"medium"} flex margin={{ top: "small" }}>
                <ModelTypeTag provider={model.provider} type={model.type} />
                <Box gap={"small"} align={"start"}>
                    <Text weight={"bold"} style={{ marginTop: -1 }}>
                        { model.name }
                    </Text>
                    <Text size={"xsmall"}>
                        { model.description ? model.description : "모델에 대한 설명이 없습니다." }
                    </Text>
                </Box>
            </Box>
            <Box align={"end"} fill={"horizontal"}>
                <Button secondary size={"small"}
                        label={<Text size={"xsmall"}>상세 보기</Text>}
                />
            </Box>
        </Box>
    </Card>
);
