import React from "react";
import { useNavigate } from "react-router-dom";

import {
    Header, Nav, Box, Layer, Button, Anchor, Text, TextInput, Avatar, ResponsiveContext,
} from "grommet";
import {
    HomeRounded, User, FormPrevious, FormNext, FormFilter, Search as SearchIcon, Notification, Emoji,
    AppsRounded, Cpu, Configure, PowerShutdown, HelpOption, MailOption,
} from "grommet-icons";

import { AppContext } from "../../../../../context";
import { Routes } from "../../../../../routes";
import { signOut } from "../../../../../services";
import { colors } from "../../../../../theme/colors";

export default function TopBar() {

    const size = React.useContext(ResponsiveContext);
    const { globalState, handleAuthPath, signOutAction } = React.useContext(AppContext);

    const isSmall = size === "small" || size === "xsmall";
    const isLogin = globalState.user !== undefined;
    const photoURL = globalState.user && globalState.user.photoURL ? globalState.user.photoURL : undefined;

    const navigate = useNavigate();

    const [ contextMenuOpen, setContextMenuOpen ] = React.useState(false);
    const [ notificationListOpen, setNotificationListOpen ] = React.useState(false);
    const [ signOutConfirmationOpen, setSignOutConfirmationOpen ] = React.useState(false);

    const onContextMenuOpen = () => setContextMenuOpen(true);
    const onContextMenuClose = () => setContextMenuOpen(false);

    const onNotificationListOpen = () => setNotificationListOpen(true);
    const onNotificationListClose = () => setNotificationListOpen(false);

    const [ searchString, setSearchString ] = React.useState("");

    return (
        <>
            { contextMenuOpen &&
                <Layer animation={"slide"} position={"top-right"} margin={{ top: "80px", right: "24px" }}
                       style={{ borderRadius: 24, transition: `all 0.3s ease-in-out 0s` }}
                       onClickOutside={onContextMenuClose}
                       modal
                       responsive
                >
                    <Box pad={"small"} gap={"small"}>
                        <Box gap={"small"} align={"center"} direction={"row"} pad={{ horizontal: "medium", vertical: "xsmall" }} margin={{ right: "small" }}>
                            <Box pad={"4px"} round border={{ color: "brand", size: "small" }}>
                                { globalState.user.photoURL ?
                                    <Avatar src={globalState.user.photoURL} size={"18px"} /> :
                                    <Avatar size={"18px"}>
                                        <User size={"12px"} />
                                    </Avatar>
                                }
                            </Box>
                            <Box pad={{ horizontal: "small" }} gap={"xsmall"}>
                                <Text size={"small"}>
                                    { globalState.user.name }
                                </Text>
                                <Anchor color={"brand"} size={"small"}
                                        onClick={() => {
                                            navigate(Routes.PATH.Profile);
                                            onContextMenuClose();
                                        }}
                                >
                                    계정 관리
                                </Anchor>
                            </Box>
                        </Box>
                        <Box height={"1px"} background={"background-contrast"}/>
                        <Box gap={"small"} pad={{ horizontal: "medium", vertical: "xsmall" }}>
                            <Box gap={"medium"} align={"center"} direction={"row"} className={"disabled-focus"}
                                 onClick={() => {
                                     navigate(Routes.PATH.AppManagement);
                                     onContextMenuClose();
                                 }}
                            >
                                <Box width={"30px"} align={"center"}>
                                    <AppsRounded size={"18px"} />
                                </Box>
                                <Text size={"small"}>
                                    앱 관리
                                </Text>
                            </Box>
                            <Box gap={"medium"} align={"center"} direction={"row"} className={"disabled-focus"}
                                 onClick={() => {
                                     navigate(Routes.PATH.ModelManagement);
                                     onContextMenuClose();
                                 }}
                            >
                                <Box width={"30px"} align={"center"}>
                                    <Cpu size={"18px"} />
                                </Box>
                                <Text size={"small"}>
                                    모델 관리
                                </Text>
                            </Box>
                            <Box gap={"medium"} align={"center"} direction={"row"} className={"disabled-focus"}
                                 onClick={() => {
                                     navigate(Routes.PATH.Settings);
                                     onContextMenuClose();
                                 }}
                            >
                                <Box width={"30px"} align={"center"}>
                                    <Configure size={"18px"} />
                                </Box>
                                <Text size={"small"}>
                                    설정
                                </Text>
                            </Box>
                            <Box gap={"medium"} align={"center"} direction={"row"} className={"disabled-focus"}
                                 onClick={() => {
                                     setSignOutConfirmationOpen(true);
                                     onContextMenuClose();
                                 }}
                            >
                                <Box width={"30px"} align={"center"}>
                                    <PowerShutdown size={"18px"} />
                                </Box>
                                <Text size={"small"}>
                                    로그아웃
                                </Text>
                            </Box>
                        </Box>
                        <Box height={"1px"} background={"background-contrast"}/>
                        <Box gap={"small"} pad={{ horizontal: "medium", top: "small", bottom: "small" }}>
                            <Box gap={"medium"} align={"center"} direction={"row"} className={"disabled-focus"}
                                 onClick={() => {
                                     onContextMenuClose();
                                 }}
                            >
                                <Box width={"30px"} align={"center"}>
                                    <HelpOption size={"20px"} />
                                </Box>
                                <Text size={"small"}>
                                    고객 센터
                                </Text>
                            </Box>
                            <Box gap={"medium"} align={"center"} direction={"row"} className={"disabled-focus"}
                                 onClick={() => {
                                     onContextMenuClose();
                                 }}
                            >
                                <Box width={"30px"} align={"center"}>
                                    <MailOption size={"18px"} />
                                </Box>
                                <Text size={"small"}>
                                    의견 보내기
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </Layer>
            }
            { notificationListOpen &&
                <Layer animation={"slide"} position={"top-right"} margin={{ top: "80px", right: "24px" }}
                       style={{ borderRadius: 24, transition: `all 0.3s ease-in-out 0s` }}
                       onClickOutside={onNotificationListClose}
                       modal
                       responsive
                >
                    <Box pad={{ vertical: "small", horizontal: "medium" }}>
                        <Box direction={"row"} gap={"small"} align={"center"} margin={{ top: "1px" }}>
                            <Emoji size={"18px"} color={"text-weak"} />
                            <Text size={"small"} color={"text-weak"} margin={{ bottom: "1px" }}>
                                알림이 없습니다.
                            </Text>
                        </Box>
                    </Box>
                </Layer>
            }
            { signOutConfirmationOpen &&
                <Layer position={"center"}
                       style={{ borderRadius: 24, transition: `all 0.3s ease-in-out 0s` }}
                       modal
                       responsive
                >
                    <Box pad={{ top: "32px", bottom: "28px", horizontal: "medium" }} gap={"medium"}>
                        <Box align={"center"}>
                            <Text size={"small"} weight={"bold"}>
                                로그아웃 하시겠습니까?
                            </Text>
                        </Box>
                        <Box direction={"row"} gap={"small"} align={"center"}>
                            <Button secondary size={"small"} label={"아니오"} style={{ width: 100 }}
                                    onClick={() => setSignOutConfirmationOpen(false)}
                            />
                            <Button primary size={"small"} label={"네"} style={{ width: 100 }}
                                    onClick={() => {
                                        signOut().then(() => {
                                            signOutAction();
                                            setSignOutConfirmationOpen(false);
                                            navigate(Routes.PATH.Home);
                                        });
                                    }}
                            />
                        </Box>
                    </Box>
                </Layer>
            }
            <Header fill={"horizontal"} margin={{ bottom: "medium" }}>
                <Nav direction={"row"} align={"center"} justify={"between"} gap={"small"} fill>
                    <Box direction={"row"} gap={"small"} align={"center"}>
                        <Button active plain={false} pad={"15px"} className={"hover-border-button"}
                                icon={<HomeRounded size={"12px"} />}
                                onClick={e => {
                                    e.currentTarget.blur();
                                    navigate(Routes.PATH.Home);
                                }}
                        />
                        { isSmall ? null :
                            <Box direction={"row"} gap={"xsmall"}>
                                <Button active plain={false} pad={"12px"} className={"hover-border-button"}
                                        icon={<FormPrevious size={"18px"} />}
                                        onClick={e => {
                                            e.currentTarget.blur();
                                            navigate(-1);
                                        }}
                                />
                                <Button active plain={false} pad={"12px"} className={"hover-border-button"}
                                        icon={<FormNext size={"18px"} />}
                                        onClick={e => {
                                            e.currentTarget.blur();
                                            navigate(1);
                                        }}
                                />
                            </Box>
                        }
                        <Box direction={"row"} gap={"small"}>
                            <TextInput size={"small"}
                                       reverse
                                       icon={<SearchIcon size={"14px"} style={{ marginRight: 8 }} />}
                                       placeholder={"검색"}
                                       value={searchString}
                                       onChange={event => setSearchString(event.target.value)}
                                       style={{
                                           minWidth: 300, padding: "11px 11px 11px 19px", border: "transparent", fontWeight: "normal", borderRadius: 18,
                                           backgroundColor: globalState.themeMode === "dark" ? colors['background-front'].dark : colors['background-back'].light,
                                       }}
                            />
                            <Button active plain={false} pad={"12px"} className={"hover-border-button"}
                                    icon={<FormFilter size={"18px"} />}
                                    onClick={e => {
                                        e.currentTarget.blur();
                                    }}
                            />
                        </Box>
                    </Box>
                    <Box direction={"row"} align={"center"} gap={"small"}>
                        { isLogin ?
                            (size !== "xsmall" &&
                                <Button active plain={false} size={"small"} className={"hover-border-button"}
                                        style={{ padding: 14 }}
                                        icon={<Notification size={"14px"} />}
                                        badge={
                                            <Box background={"brand"} round width={"12px"} height={"12px"} align={"center"} justify={"center"}>
                                                <Text size={"10px"}>{0}</Text>
                                            </Box>
                                        }
                                        onClick={e => {
                                            e.currentTarget.blur();
                                            if (isLogin) {
                                                onNotificationListOpen();
                                            }
                                        }}
                                />
                            ) : null
                        }
                        { isSmall ?
                            <Button active plain={false} reverse pad={"6px"} className={"hover-border-button"}
                                    icon={
                                        <Box round overflow={"hidden"} width={"30px"} height={"30px"} align={"center"} justify={"center"}>
                                            { photoURL ? <Avatar src={photoURL} size={"22px"} /> : <User size={"22px"} /> }
                                        </Box>
                                    }
                                    onClick={e => {
                                        e.currentTarget.blur();
                                        if (!isLogin) {
                                            handleAuthPath({ path: Routes.AUTH_PATH.Login });
                                        }
                                    }}
                            /> :
                            <Button active plain={false} reverse pad={{ vertical: "6px", horizontal: "22px" }} gap={"small"} className={"hover-border-button"}
                                    icon={
                                        <Box pad={photoURL ? "5px" : "7px"} round border={{ color: "brand", size: "small" }}>
                                            { photoURL ? <Avatar src={photoURL} size={"16px"} /> : <User size={"12px"} /> }
                                        </Box>
                                    }
                                    label={<Text size={"small"}>{ isLogin ? globalState.user.name : "로그인" }</Text>}
                                    onClick={e => {
                                        e.currentTarget.blur();
                                        if (!isLogin) {
                                            handleAuthPath({ path: Routes.AUTH_PATH.Login });
                                        }
                                        else {
                                            onContextMenuOpen();
                                        }
                                    }}
                            />
                        }
                    </Box>
                </Nav>
            </Header>
        </>
    );

}
