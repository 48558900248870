import React from "react";
import uuid from "short-uuid";

import {
    Box, Button, Layer, Tabs, Tab, Form, FormField, TextInput, MaskedInput, Image, Text
} from "grommet";
import { Refresh } from "grommet-icons";
import {
    TbHeading, TbBold, TbItalic, TbUnderline, TbStrikethrough, TbListNumbers, TbList, TbQuote,
    TbPhoto, TbVideo, TbLink, TbArrowBackUp, TbArrowForwardUp,
} from "react-icons/tb";
import ReactPlayer from "react-player";
import imageCompression from "browser-image-compression";

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../config/firebase";

import {
    EditorState, RichUtils, AtomicBlockUtils, CompositeDecorator, SelectionState
} from "draft-js";
import "draft-js/dist/Draft.css";
import Editor from "@draft-js-plugins/editor";

import createImagePlugin from "@draft-js-plugins/image";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createVideoPlugin from "@draft-js-plugins/video";

import DragDropFile from "../form/file";
import { AppContext } from "../../context";
import { isEmptyStr } from "../../utils/strings";

const imagePlugin = createImagePlugin();
const videoPlugin = createVideoPlugin();
const { types } = videoPlugin;
const linkifyPlugin = createLinkifyPlugin();

const plugins = [ imagePlugin, videoPlugin, linkifyPlugin ];

const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a rel="noopener noreferrer" target="_blank" href={url} style={{ color: "#85cf81", fontWeight: "bolder" }}>
            { props.children }
        </a>
    );
};

const linkDecorator = new CompositeDecorator([
    {
        strategy: (contentBlock, callback, contentState) => {
            contentBlock.findEntityRanges((character) => {
                const entityKey = character.getEntity();
                return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
            }, callback);
        },
        component: Link
    }
]);

const decorators = [ linkDecorator ];

function blockStyleFn(block) {
    const type = block.getType();

    if (type === "blockquote") {
        return "editor-blockquote";

    }

    return null;
}

function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const image_data = atob(b64Data.split(",")[1]);

    const arraybuffer = new ArrayBuffer(image_data.length);
    const view = new Uint8Array(arraybuffer);

    for (let i = 0; i < image_data.length; i++) {
        view[i] = image_data.charCodeAt(i) & 0xff;
    }

    return new Blob([arraybuffer], { type: contentType });
}

const moveSelectionToEnd = editorState => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    const selection = new SelectionState({
        anchorKey: key,
        anchorOffset: length,
        focusKey: key,
        focusOffset: length,
    });
    return EditorState.forceSelection(editorState, selection);
};

export default function CustomEditor({ index, editorState, setEditorState }) {

    const editorRef = React.useRef(null);
    const { globalState } = React.useContext(AppContext);

    const changeEditorState = (newState) => {
        if (index !== undefined) {
            setEditorState(index, newState);
        }
        else {
            setEditorState(newState);
        }
    };

    const [ imageUploadLayerOpen, setImageUploadLayerOpen ] = React.useState(false);
    const [ imageURL, setImageURL ] = React.useState(undefined);

    const handleImageFile = async (file) => {
        const compressedImage = await imageCompression(file, {
            maxWidthOrHeight: 1024,
            maxSizeMB: 1,
        });
        let reader = new FileReader();
        reader.onload = () => {
            const blob = b64toBlob(reader.result, file.type);
            const imageRef = ref(storage, `/userData/${globalState.user.id}/images/${uuid.generate()}.jpg`);
            uploadBytes(imageRef, blob)
                .then(uploaded => getDownloadURL(imageRef))
                .then(url => setImageURL(url))
                .catch(error => console.error(error));
        };
        reader.readAsDataURL(compressedImage);
    };

    const closeImageUploadLayer = () => {
        setImageURL(undefined);
        changeEditorState(moveSelectionToEnd(editorState));
        setImageUploadLayerOpen(false);
    }

    const [ videoUploadLayerOpen, setVideoUploadLayerOpen ] = React.useState(false);
    const [ videoURL, setVideoURL ] = React.useState("");

    const closeVideoUploadLayer = () => {
        setVideoURL("");
        changeEditorState(moveSelectionToEnd(editorState));
        setVideoUploadLayerOpen(false);
    };

    const [ linkUploadLayerOpen, setLinkUploadLayerOpen ] = React.useState(false);
    const [ linkText, setLinkText ] = React.useState("");
    const [ linkURL, setLinkURL ] = React.useState("");

    const closeLinkUploadLayer = () => {
        setLinkText("");
        setLinkURL("");
        changeEditorState(moveSelectionToEnd(editorState));
        setLinkUploadLayerOpen(false);
    };

    const handleToggleClick = (e, inlineStyle) => {
        e.preventDefault();
        changeEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const handleBlockClick = (e, blockType) => {
        e.preventDefault();
        changeEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const handleInsertImage = (e) => {
        e.preventDefault();
        setImageUploadLayerOpen(true);
    };

    const insertImage = () => {
        setImageUploadLayerOpen(false);

        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("image", "IMMUTABLE", { src: imageURL });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity
        });

        changeEditorState(moveSelectionToEnd(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")));
        setImageURL(undefined);
    };

    const handleInsertVideo = (e) => {
        e.preventDefault();
        setVideoUploadLayerOpen(true);
    };

    const insertVideo = () => {
        setVideoUploadLayerOpen(false);

        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(types.VIDEOTYPE, "IMMUTABLE", { src: videoURL });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity
        });

        changeEditorState(moveSelectionToEnd(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")));
        setVideoURL("");
    };

    const handleAddLink = (e) => {
        e.preventDefault();

        const selection = editorState.getSelection();
        const anchorKey = selection.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentBlock = currentContent.getBlockForKey(anchorKey);

        const start = selection.getStartOffset();
        const end = selection.getEndOffset();
        const selectedText = currentBlock.getText().slice(start, end);

        setLinkText(selectedText);
        setLinkUploadLayerOpen(true);
    };

    const addLink = () => {
        setLinkUploadLayerOpen(false);

        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", {
            url: linkURL
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity
        });

        changeEditorState(moveSelectionToEnd(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey)));
        setLinkURL("");
    };

    return (
        <Box fill>
            { imageUploadLayerOpen &&
                <Layer position={"center"} background={"background-back"}
                       style={{ borderRadius: 24 }}
                       onClickOutside={closeImageUploadLayer}
                       modal
                       responsive
                >
                    <Box width={"480px"} background={"background-back"} round={"medium"} pad={"medium"} gap={"medium"}>
                        <Box justify={"start"} direction={"row"} align={"center"}>
                            <Tabs activeIndex={1} justify={"start"}>
                                <Tab title={"이미지 추가"} style={{ pointerEvents: "none" }} />
                            </Tabs>
                        </Box>
                        <Box gap={"small"}>
                            { imageURL === undefined &&
                                <Box>
                                    <DragDropFile id={"imageInsert"} width={"100%"} height={"360px"} handleFile={handleImageFile} />
                                </Box>
                            }
                            { imageURL !== undefined &&
                                <Box round={"small"} overflow={"hidden"}
                                     className={"hover-overlay-container"}
                                >
                                    <Image src={imageURL} width={"100%"} fit={"contain"} style={{ maxHeight: 360 }} />
                                    <Button plain
                                            icon={
                                                <Box gap={"xxsmall"} align={"center"} background={"transparent"}
                                                     pad={{ horizontal: "small" }}
                                                >
                                                    <Refresh size={"16px"} />
                                                    <Text size={"xsmall"} truncate>
                                                        다시 선택
                                                    </Text>
                                                </Box>
                                            }
                                            onClick={() => setImageURL(undefined)}
                                    />
                                </Box>
                            }
                            <Box direction={"row"} justify={"end"} gap={"small"} margin={{ top: "medium" }}>
                                <Button size={"small"} label={"취소"} secondary
                                        onClick={() => closeImageUploadLayer()}
                                />
                                <Button size={"small"} label={"추가"} primary
                                        onClick={() => insertImage()}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Layer>
            }
            { videoUploadLayerOpen &&
                <Layer position={"center"} background={"background-back"}
                       style={{ borderRadius: 24 }}
                       onClickOutside={closeVideoUploadLayer}
                       modal
                       responsive
                >
                    <Box width={"large"} background={"background-back"} round={"medium"} pad={"medium"} gap={"medium"}>
                        <Box justify={"start"} direction={"row"} align={"center"}>
                            <Tabs activeIndex={1} justify={"start"}>
                                <Tab title={"비디오 추가"} style={{ pointerEvents: "none" }} />
                            </Tabs>
                        </Box>
                        <Box gap={"small"}>
                            <Form onSubmit={() => insertVideo()}>
                                <FormField name={"videoURL"} htmlFor={"videoURL"} label={"링크 주소"} required>
                                    <MaskedInput id={"videoURL"} name={"videoURL"}
                                                 mask={[{ fixed: "https://" }, { regexp: /^.*$/ }]}
                                                 value={videoURL}
                                                 onChange={(event) => setVideoURL(event.target.value)}
                                                 style={{ fontSize: 14, }}
                                    />
                                </FormField>
                                { !isEmptyStr(videoURL) &&
                                    <Box>
                                        <ReactPlayer url={videoURL} width={"100%"} height={"360px"} className="react-player" />
                                    </Box>
                                }
                                <Box direction={"row"} justify={"end"} gap={"small"} margin={{ top: "medium" }}>
                                    <Button size={"small"} label={"취소"} secondary
                                            onClick={() => closeVideoUploadLayer()}
                                    />
                                    <Button size={"small"} type={"submit"} label={"추가"} primary
                                            style={{ fontWeight: "bolder", color: "#eeeeee", }}
                                    />
                                </Box>
                            </Form>
                        </Box>
                    </Box>
                </Layer>
            }
            { linkUploadLayerOpen &&
                <Layer position={"center"} background={"background-back"}
                       style={{ borderRadius: 24 }}
                       onClickOutside={closeLinkUploadLayer}
                       modal
                       responsive
                >
                    <Box width={"medium"} background={"background-back"} round={"medium"} pad={"medium"} gap={"medium"}>
                        <Box justify={"start"} direction={"row"} align={"center"}>
                            <Tabs activeIndex={1} justify={"start"}>
                                <Tab title={"링크 입력"} style={{ pointerEvents: "none" }} />
                            </Tabs>
                        </Box>
                        <Box gap={"small"}>
                            <Form onSubmit={() => addLink()}>
                                <FormField name={"linkText"} htmlFor={"linkText"} label={"링크 대상 문자"} disabled className={"form-field-transparent"}>
                                    <TextInput id={"linkText"}
                                               value={linkText}
                                               style={{ backgroundColor: "transparent", fontSize: 14, }}
                                    />
                                </FormField>
                                <FormField name={"linkURL"} htmlFor={"linkURL"} label={"링크 주소"} required>
                                    <MaskedInput id={"linkURL"} name={"linkURL"}
                                                 mask={[{ fixed: "https://" }, { regexp: /^.*$/ }]}
                                                 value={linkURL}
                                                 onChange={(event) => setLinkURL(event.target.value)}
                                                 style={{ fontSize: 14, }}
                                    />
                                </FormField>
                                <Box direction={"row"} justify={"end"} gap={"small"} margin={{ top: "medium" }}>
                                    <Button size={"small"} label={"취소"} secondary
                                            onClick={() => closeLinkUploadLayer()}
                                    />
                                    <Button size={"small"} type={"submit"} label={"입력"} primary
                                            style={{ fontWeight: "bolder", color: "#eeeeee", }}
                                    />
                                </Box>
                            </Form>
                        </Box>
                    </Box>
                </Layer>
            }
            <Box round={"small"} pad={{ vertical: "small", horizontal: "small" }} gap={"small"} background={"background-back"}>
                <Box direction={"row"} gap={"10px"}>
                    <Button icon={<TbHeading color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            primary={!(RichUtils.getCurrentBlockType(editorState) === "header-one")}
                            secondary={(RichUtils.getCurrentBlockType(editorState) === "header-one")}
                            onMouseDown={e => handleBlockClick(e, "header-one")}
                    />
                    <Button icon={<TbBold color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            primary={!editorState.getCurrentInlineStyle().has("BOLD")}
                            secondary={editorState.getCurrentInlineStyle().has("BOLD")}
                            onMouseDown={e => handleToggleClick(e, "BOLD")}
                    />
                    <Button icon={<TbItalic color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            primary={!editorState.getCurrentInlineStyle().has("ITALIC")}
                            secondary={editorState.getCurrentInlineStyle().has("ITALIC")}
                            onMouseDown={e => handleToggleClick(e, "ITALIC")}
                    />
                    <Button icon={<TbUnderline color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            primary={!editorState.getCurrentInlineStyle().has("UNDERLINE")}
                            secondary={editorState.getCurrentInlineStyle().has("UNDERLINE")}
                            onMouseDown={e => handleToggleClick(e, "UNDERLINE")}
                    />
                    <Button icon={<TbStrikethrough color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            primary={!editorState.getCurrentInlineStyle().has("STRIKETHROUGH")}
                            secondary={editorState.getCurrentInlineStyle().has("STRIKETHROUGH")}
                            onMouseDown={e => handleToggleClick(e, "STRIKETHROUGH")}
                    />
                    <Box width={"1px"} background={"brand"} margin={{ vertical: "4px" }} />
                    <Button icon={<TbListNumbers color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            primary={!(RichUtils.getCurrentBlockType(editorState) === "ordered-list-item")}
                            secondary={(RichUtils.getCurrentBlockType(editorState) === "ordered-list-item")}
                            onMouseDown={e => handleBlockClick(e, "ordered-list-item")}
                    />
                    <Button icon={<TbList color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            primary={!(RichUtils.getCurrentBlockType(editorState) === "unordered-list-item")}
                            secondary={(RichUtils.getCurrentBlockType(editorState) === "unordered-list-item")}
                            onMouseDown={e => handleBlockClick(e, "unordered-list-item")}
                    />
                    <Button icon={<TbQuote color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            primary={!(RichUtils.getCurrentBlockType(editorState) === "blockquote")}
                            secondary={(RichUtils.getCurrentBlockType(editorState) === "blockquote")}
                            onMouseDown={e => handleBlockClick(e, "blockquote")}
                    />
                    <Box width={"1px"} background={"brand"} margin={{ vertical: "4px" }} />
                    <Button primary icon={<TbPhoto color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            onMouseDown={handleInsertImage}
                    />
                    <Button primary icon={<TbVideo color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            onMouseDown={handleInsertVideo}
                    />
                    <Button primary icon={<TbLink color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            disabled={editorState.getSelection().isCollapsed()}
                            onMouseDown={handleAddLink}
                    />
                    <Box width={"1px"} background={"brand"} margin={{ vertical: "4px" }} />
                    <Button primary icon={<TbArrowBackUp color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            disabled={editorState.getUndoStack().size <= 0}
                            onMouseDown={() => changeEditorState(EditorState.undo(editorState))}
                    />
                    <Button primary icon={<TbArrowForwardUp color={"#eeeeee"} size={14} />}
                            style={{ width: 22, height: 22, borderRadius: 8, padding: 0, textAlign: "-webkit-center" }}
                            disabled={editorState.getRedoStack().size <= 0}
                            onMouseDown={() => changeEditorState(EditorState.redo(editorState))}
                    />
                </Box>
            </Box>
            <Box margin={{ top: "small" }} pad={{ vertical: "small", horizontal: "small" }} round={"small"} background={"background-back"}
                 flex overflow={"auto"} className={"hide-scrollbar"}
            >
                <Editor ref={editorRef}
                        placeholder={"설명을 작성해 주세요."}
                        editorState={editorState}
                        onChange={changeEditorState}
                        plugins={plugins}
                        decorators={decorators}
                        blockStyleFn={blockStyleFn}
                />
            </Box>
        </Box>
    );

}
