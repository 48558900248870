import React from "react";
import { motion } from "framer-motion";

import {
    Box, Grid, Heading, Form, FormField, MaskedInput, TextInput, Button, Text, CheckBox, Image, ResponsiveContext, Card
} from "grommet";
import { FormClose, FingerPrint, Google, Apple } from "grommet-icons";

import { AppContext } from "../../../context";
import { getLocalStorageValue, setLocalStorageValue, removeLocalStorageValue } from "../../../utils/browser";
import { Routes } from "../../../routes";
import { LoaderModal } from "../../../components";
import { AuthProvider, signInWithEmail, signInWithGoogle } from "../../../services";
import { createNotificationMessage, NotificationState } from "../../../utils/notifications";

const DEFAULT_VALUE = {
    email: "",
    password: "",
};

function isEmailValidate(email) {
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
}

export default function Login({ onClose }) {

    const { globalState, signInAction, handleAuthPath } = React.useContext(AppContext);
    const size = React.useContext(ResponsiveContext);
    const isSmall = (size === "small" || size === "xsmall");
    const isDarkMode = globalState.themeMode === "dark";

    const rememberMeDefault = getLocalStorageValue("USER_REMEMBER_ME") === "true";
    const email = getLocalStorageValue("USER_EMAIL");
    const password = getLocalStorageValue("USER_PASSWORD");

    const [ value, setValue ] = React.useState(rememberMeDefault ? {
        email: email, password: password
    } : DEFAULT_VALUE);
    const [ rememberMe, setRememberMe ] = React.useState(rememberMeDefault);

    const [ isInProgress, setInProgress ] = React.useState(false);

    const signInByAuth0 = async (authValue) => {
        setInProgress(true);

        try {
            const result = await signInWithEmail(authValue);
            if (!result.emailVerified) {
                handleAuthPath({ path: Routes.AUTH_PATH.VerifyingEmail });
            }
            else if (!result.profileCreated) {
                handleAuthPath({ path: Routes.AUTH_PATH.CreateProfile });
            }
            else {
                if (rememberMe) {
                    setLocalStorageValue("USER_REMEMBER_ME", "true");
                    setLocalStorageValue("USER_EMAIL", authValue.email);
                    setLocalStorageValue("USER_PASSWORD", authValue.password);
                }
                else {
                    removeLocalStorageValue("USER_REMEMBER_ME");
                    removeLocalStorageValue("USER_EMAIL");
                    removeLocalStorageValue("USER_PASSWORD");
                }

                await signInWithSuccess(result.user);
            }
        }
        catch (error) {
            console.error(error);
            createNotificationMessage(NotificationState.FAILED, "로그인에 실패했습니다.");
        }
        finally {
            setInProgress(false);
        }
    }

    const signInByOAuth2 = async (provider) => {
        let user;
        switch (provider) {
            case AuthProvider.Google:
                user = await signInWithGoogle();
                break;
            case AuthProvider.Apple:
            case AuthProvider.Facebook:
            case AuthProvider.Instagram:
            default:
                break;
        }

        if (user) {
            await signInWithSuccess(user);
        }
        else {
            createNotificationMessage(NotificationState.FAILED, "로그인에 실패했습니다.");
        }
    };

    const signInWithSuccess = async (user) => {
        signInAction({ user: user });
        handleAuthPath({ path: undefined });
        createNotificationMessage(NotificationState.SUCCESS, `${user.name}님 환영합니다.`);
    };

    return (
        <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{ width: "100%", height: "100%" }}
        >
            { isInProgress === true &&  <LoaderModal message={"처리 중..."} /> }
            <Card round={"medium"}>
                <Box style={{ position: "absolute", top: 24, right: 24 }}>
                    <Button icon={<FormClose />} onClick={onClose} style={{ borderRadius: 14 }} />
                </Box>
                <Box pad={size === "small" ? "xlarge" : "large"} gap={"medium"}>
                    <Box direction={"row"} gap={"medium"}>
                        <FingerPrint size={"36px"} style={{ alignSelf: "center" }} />
                        <Heading level={"3"} style={{ fontWeight: 900, paddingTop: 3 }}>
                            { Routes.TITLES.Login }
                        </Heading>
                    </Box>
                    <Grid columns={!isSmall ? ["300px", "1px", "auto"] : "100%"} gap={"40px"}>
                        <Form value={value}
                              validate={"blur"}
                              onChange={(nextValue) => setValue(nextValue)}
                              onSubmit={(event) => signInByAuth0(event.value)}
                        >
                            <FormField label={"이메일"} htmlFor={"email"} name={"email"} margin={{ bottom: "8px" }}
                                       validate={[
                                           (email) => {
                                               if (!email || email.length < 1) return "필수 입력 사항입니다.";
                                               else if (!isEmailValidate(email)) return "잘못된 이메일 주소입니다";
                                               else return undefined;
                                           }
                                       ]}
                            >
                                <MaskedInput id={"email"} name={"email"} size={"small"}
                                             mask={[
                                                 { regexp: /^[\w\-_.]+$/, placeholder: "abc" },
                                                 { fixed: "@" },
                                                 { regexp: /^[\w]+$/, placeholder: "aibada" },
                                                 { fixed: "." },
                                                 { regexp: /^[\w]+$/, placeholder: "com" },
                                             ]}
                                />
                            </FormField>
                            <FormField label={"패스워드"} htmlFor={"password"} name={"password"} margin={{ bottom: "8px" }}
                                       validate={[
                                           (password) => {
                                               if (!password || password.length < 1) return "필수 입력 사항입니다.";
                                               else if (password.length < 6) return "패스워드는 최소 6자 이상으로 입력하세요.";
                                               else return undefined;
                                           }
                                       ]}
                            >
                                <TextInput id={"password"} name={"password"} type={"password"} size={"small"} />
                            </FormField>
                            <Box direction={"row"} gap={"small"} margin={{ top: "16px" }}>
                                <CheckBox label={<Text size={"xsmall"} alignSelf={"center"} style={{ marginTop: 1 }}>로그인 정보를 기억합니다.</Text>}
                                          checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}
                                />
                            </Box>
                            <Box direction={"row"} gap={"small"} justify={"end"} margin={{ top: "medium" }}>
                                <Button size={"small"}
                                        label={<Text color={"text"} size={"small"}>회원가입</Text>}
                                        onClick={() => handleAuthPath({ path: Routes.AUTH_PATH.Register })}
                                />
                                <Button primary={isDarkMode} secondary={!isDarkMode} size={"small"}
                                        label={<Text color={"text"} size={"small"}>로그인</Text>}
                                        type={"submit"}
                                />
                            </Box>
                        </Form>
                        <Box height={isSmall ? "1px" : "210px"} background={"background-contrast"} />
                        <Box gap={"small"}>
                            <Box height={"24px"} justify={"end"} margin={{ vertical: "6px" }}>
                                <Text size={"small"}>
                                    혹은, 아래의 소셜 로그인으로 시작할 수 있습니다.
                                </Text>
                            </Box>
                            <Box direction={"row"} gap={"xsmall"}>
                                <Button plain={false}
                                        style={{ padding: 8 }}
                                        icon={<Google size={"22px"} color={"plain"} />}
                                        onClick={e => {
                                            e.currentTarget.blur();
                                            signInByOAuth2(AuthProvider.Google);
                                        }}
                                />
                                <Button plain={false}
                                        style={{ padding: 8 }}
                                        icon={<Apple size={"22px"} />}
                                        onClick={e => {
                                            e.currentTarget.blur();
                                        }}
                                />
                                <Button plain={false}
                                        style={{ padding: 8 }}
                                        icon={<Image src={"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/2021_Facebook_icon.svg/1024px-2021_Facebook_icon.svg.png?20220821121039"} width={"22px"} />}
                                        onClick={e => {
                                            e.currentTarget.blur();
                                        }}
                                />
                                <Button plain={false}
                                        style={{ padding: 8 }}
                                        icon={<Image src={"https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Instagram.svg/1200px-Instagram.svg.png"} width={"22px"} />}
                                        onClick={e => {
                                            e.currentTarget.blur();
                                        }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Box>
            </Card>
        </motion.div>
    );

}
