import uuid from "short-uuid";

import { SearchAdvanced, Yoga } from "grommet-icons";
import { doc, addDoc, getDocs, setDoc, collection, query, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes, uploadString } from "firebase/storage";
import {
    signInWithPopup, GoogleAuthProvider, browserSessionPersistence,
    createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword,
    updateProfile, signOut as signOutFirebase,
} from "firebase/auth";

import { db, storage, auth, googleAuthProvider } from "../config/firebase";
import { setSessionStorageValue } from "../utils/browser";
import { utcDateTime } from "../utils/datetime";
import { colors } from "../theme/colors";
import { createNotificationMessage, NotificationState } from "../utils/notifications";
import { createModelArtifacts, createModelWeightsData } from "../utils/tensorflow";

export const AuthProvider = {
    Google: "google",
    Apple: "apple",
    Facebook: "facebook",
    Instagram: "instagram",
    Firebase: "firebase",
};

const DESC_MESSAGE = {
    TF_DEFAULT_MODEL: "시스템에서 제공하는 기본 모델입니다.",
    TM_MODEL: "TeachableMachine에서 학습한 모델입니다.",
};

export const signOut = () => {
    return new Promise((resolve, reject) => {
        signOutFirebase(auth)
            .then(() => resolve())
            .catch(error => reject(error));
    });
};

export const createProfile = async (profileImage, name) => {
    return new Promise((resolve, reject) => {
        let userData = {
            authId: auth.currentUser.uid,
            provider: AuthProvider.Firebase,
            email: auth.currentUser.email,
            name: name,
            numberOfFollowers: 0,
            numberOfFollows: 0,
            createdBy: utcDateTime(),
            deleted: false,
        };
        let addedUser = undefined;

        addDoc(collection(db, "users"), userData)
            .then(added => {
                addedUser = { ...userData, id: added.id };
                if (profileImage && profileImage.blob) {
                    const extension = getExtension(profileImage.blob.name);
                    const profileImageRef = ref(storage, `/userData/${added.id}/images/${uuid.generate()}.${extension}`);

                    return uploadBytes(profileImageRef, profileImage.blob);
                }
            })
            .then(uploaded => {
                if (uploaded) {
                    return getDownloadURL(uploaded.ref);
                }
            })
            .then(photoURL => {
                if (photoURL) {
                    userData["photoURL"] = photoURL;
                    addedUser["photoURL"] = photoURL;
                }
                return setDoc(doc(db, "users", addedUser.id), userData)
            })
            .then(() => updateProfile(auth.currentUser, {
                displayName: userData.name,
                photoURL: userData.photoURL,
            }))
            .then(() => resolve(addedUser))
            .catch(error => reject(error));
    });
};

export const registerWithEmailAndPassword = async (email, password) => {
    await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(auth.currentUser);
};

const waitTimer = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const waitForEmailVerification = async () => {
    return new Promise((resolve, reject) => {
        const emailVerification = () => {
            const user = auth.currentUser;

            if (!user) {
                reject(new Error("Invalid auth user!"));
            }
            else if (!user.emailVerified) {
                waitTimer(5000).then(() => {
                    auth.currentUser.reload()
                        .then(() => emailVerification());
                });
            }
            else {
                resolve();
            }
        };
        emailVerification();
    });
};

export const signInWithEmail = async (inputData) => {
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(auth, inputData.email, inputData.password)
            .then(() => {
                if (!auth.currentUser.emailVerified) {
                    resolve({
                        emailVerified: false,
                    });
                }
                else {
                    const q = query(
                        collection(db, "users"),
                        where("email", "==", inputData.email),
                        where("deleted", "==", false)
                    );

                    getDocs(q)
                        .then(querySnapshot => {
                            if (querySnapshot.empty) {
                                resolve({
                                    emailVerified: true,
                                    profileCreated: false,
                                });
                            }
                            else {
                                const userRef = querySnapshot.docs[0];
                                const userData = userRef.data();
                                userData["id"] = userRef.id;
                                resolve({
                                    emailVerified: true,
                                    profileCreated: true,
                                    user: userData,
                                });
                            }
                        })
                        .catch(error => reject(error));
                }
            })
            .catch(error => reject(error));
    });
};

export const signInWithGoogle = async () => {
    await auth.setPersistence(browserSessionPersistence);

    const user = auth.currentUser;
    if (user) {
        return await getUserData(user, AuthProvider.Google);
    }
    else {
        const result = await signInWithPopup(auth, googleAuthProvider);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        setSessionStorageValue("USER_GOOGLE_TOKEN", token);

        if (result.user !== null && result.user !== undefined) {
            return await getUserData(result.user, AuthProvider.Google);
        }
    }

    return undefined;
};

const getUserData = async (user, provider) => {
    const currentUser = auth.currentUser;
    const q = query(
        collection(db, "users"),
        where("authId", "==", currentUser.uid),
        where("deleted", "==", false)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
        const userData = {
            authId: user.uid,
            provider: provider,
            email: user.email,
            name: user.displayName,
            photoURL: user.photoURL,
            numberOfFollowers: 0,
            numberOfFollows: 0,
            deleted: false,
        };
        const docRef = await addDoc(collection(db, "users"), userData);
        userData["id"] = docRef.id;
        return userData;
    }
    else {
        const userRef = querySnapshot.docs[0];
        const userData = userRef.data();
        return {
            id: userRef.id,
            authId: user.uid,
            provider: user.providerId,
            email: user.email,
            name: userData.name,
            photoURL: userData.photoURL,
            numberOfFollowers: userData.numberOfFollowers,
            numberOfFollows: userData.numberOfFollows,
            deleted: false,
        };
    }
};

export const getUsers = async () => {
    const users = [];

    const usersSnapShot = await getDocs(collection(db, "users"));
    usersSnapShot.forEach(documentSnapshot => {
        users.push({
            ...documentSnapshot.data(),
            id: documentSnapshot.id,
        });
    });

    return users;
};

export const saveModel = async (model, labels, trainedModel, userId) => {
    const uuidModelPath = uuid.generate();

    const modelArtifacts = createModelArtifacts(trainedModel);
    const modelWeights = await createModelWeightsData(trainedModel);

    const weightsRef = ref(storage, `/userData/${userId}/models/${uuidModelPath}/model.weights.bin`);
    await uploadBytes(weightsRef, modelWeights)
        .catch(error => {
            console.error(error);
            createNotificationMessage(NotificationState.FAILED, "모델 파일 업로드에 실패했습니다.");
        });
    const weightsUrl = await getDownloadURL(weightsRef);

    const metadataRef = ref(storage, `/userData/${userId}/models/${uuidModelPath}/metadata.json`);
    await uploadString(metadataRef, JSON.stringify(modelArtifacts))
        .catch(error => {
            console.error(error);
            createNotificationMessage(NotificationState.FAILED, "모델 정보 파일 업로드에 실패했습니다.");
        });
    const metadataUrl = await getDownloadURL(metadataRef);

    let labelData = [];
    labels.forEach((label) => {
        labelData.push(label.name);
    });

    const modelDoc = {
        name: model.name,
        provider: model.provider,
        type: model.type,
        labels: labelData,
        metadata: metadataUrl,
        weights: weightsUrl,
        useDefault: false,
        createdBy: userId,
        created: utcDateTime(),
        lastModified: utcDateTime(),
        deleted: false,
    };

    let success = true;
    if (model.id) {
        await setDoc(doc(db, "models", model.id), modelDoc)
            .catch(error => {
                success = false;
                console.error(error);
                createNotificationMessage(NotificationState.FAILED, "모델 데이터 저장에 실패했습니다.");
            });
    }
    else {
        const added = await addDoc(collection(db, "models"), modelDoc)
            .catch(error => {
                success = false;
                console.error(error);
                createNotificationMessage(NotificationState.FAILED, "모델 데이터 저장에 실패했습니다.");
            });
        modelDoc["id"] = added.id;
    }

    createNotificationMessage(NotificationState.SUCCESS, "모델 데이터가 저장 되었습니다.");

    return success;
};

export const saveTmModel = async (model, labels, userId) => {
    const modelDoc = {
        name: model.name,
        provider: model.provider,
        type: model.type,
        description: DESC_MESSAGE.TM_MODEL,
        externalUrl: model.url,
        labels: labels,
        useDefault: false,
        createdBy: userId,
        created: utcDateTime(),
        lastModified: utcDateTime(),
        deleted: false,
    };

    let success = true;
    await addDoc(collection(db, "models"), modelDoc)
        .catch(error => {
            console.error(error);
            createNotificationMessage(NotificationState.FAILED, "모델 데이터 저장에 실패했습니다.");
            success = false;
        });

    createNotificationMessage(NotificationState.SUCCESS, "모델 데이터가 저장 되었습니다.");

    return success;
};

export const saveApp = async (appData, coverImage, userId) => {
    const extension = getExtension(coverImage.blob.name);
    const coverImageRef = ref(storage, `/userData/${userId}/images/${uuid.generate()}.${extension}`);

    const result = await uploadBytes(coverImageRef, coverImage.blob);
    const url = await getDownloadURL(result.ref);

    const appDoc = {
        ...appData,
        coverImage: url,
        createdBy: userId,
        created: utcDateTime(),
        lastModified: utcDateTime(),
        deleted: false,
    };

    let success = true;
    await addDoc(collection(db, "apps"), appDoc)
        .catch(error => {
            console.error(error);
            createNotificationMessage(NotificationState.FAILED, "앱 데이터 저장에 실패했습니다.");
            success = false;
        });

    createNotificationMessage(NotificationState.SUCCESS, "앱 데이터가 저장 되었습니다.");

    return success;
};

function getExtension(filename) {
    return filename.split(".").pop();
}

export const ModelProvider = {
    Tensorflow: "tensorflow",
    TeachableMachine: "teachablemachine",
};

ModelProvider.keyOf = (value) => {
    return Object.keys(ModelProvider).find((key) => ModelProvider[key] === value);
};

export const ModelType = {
    ImageClassification: "image/classification",
    ObjectDetection: "image/detection",
    PoseDetection: "pose",
};

export const TfModelType = {
    ImageClassification: {
        label: "이미지 분류",
        value: ModelType.ImageClassification,
        color: colors["green"],
        icon: <SearchAdvanced size={"18px"} color={colors["green"]} />,
        smallIcon: <SearchAdvanced size={"14px"} color={colors["green"]} />,
        plainIcon: <SearchAdvanced size={"14px"} />,
        defaultValueAvailable: false,
    },
    // ObjectDetection: {
    //     label: "사물 인식",
    //     value: ModelType.ObjectDetection,
    //     color: colors["red!"],
    //     icon: <Select size={"18px"} color={colors["red!"]} />,
    //     smallIcon: <Select size={"14px"} color={colors["red!"]} />,
    //     plainIcon: <Select size={"14px"} />,
    //     defaultValueAvailable: true,
    // },
    // PoseDetection: {
    //     label: "동작 인식",
    //     value: ModelType.PoseDetection,
    //     color: colors["purple!"],
    //     icon: <Yoga size={"18px"} color={colors["purple!"]} />,
    //     smallIcon: <Yoga size={"14px"} color={colors["purple!"]} />,
    //     plainIcon: <Yoga size={"14px"} />,
    //     defaultValueAvailable: true,
    // },
};

TfModelType.valueOf = (value) => {
    return TfModelType[Object.keys(TfModelType).find((key) => TfModelType[key].value === value)];
};

export const TmModelType = {
    ImageClassification: {
        label: "이미지 분류",
        value: ModelType.ImageClassification,
        color: colors["orange"],
        icon: <SearchAdvanced size={"18px"} color={colors["orange"]} />,
        smallIcon: <SearchAdvanced size={"14px"} color={colors["orange"]} />,
        plainIcon: <SearchAdvanced size={"14px"} />,
    },
    PoseDetection: {
        label: "동작 인식",
        value: ModelType.PoseDetection,
        color: colors["purple"],
        icon: <Yoga size={"18px"} color={colors["purple"]} />,
        smallIcon: <Yoga size={"14px"} color={colors["purple"]} />,
        plainIcon: <Yoga size={"14px"} />,
    },
};

TmModelType.valueOf = (value) => {
    return TmModelType[Object.keys(TmModelType).find((key) => TmModelType[key].value === value)];
};
