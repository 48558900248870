import { Text } from "grommet";

export function strContains(str, contains) {
    return str && str.length > 0 && str.indexOf(contains) > -1;
}

export function strReplace(str, old, replace) {
    return str.split(old).join(replace);
}

export function strStartsWith(str, starts) {
    return str && str.indexOf(starts) === 0;
}

export function isEmptyStr(str) {
    return str === null || str === undefined || str.trim() === "" || str.trim() === "null" ||  str.trim() === "undefined";
}

const useNodeBuffer = typeof Buffer !== "undefined" &&
    (typeof Blob === "undefined" || typeof atob === "undefined" ||
        typeof btoa === "undefined");

export function base64StringToArrayBuffer(str) {
    if (useNodeBuffer) {
        const buf = Buffer.from(str, "base64");
        return buf.buffer.slice(buf.byteOffset, buf.byteOffset + buf.byteLength);
    }
    const s = atob(str);
    const buffer = new Uint8Array(s.length);
    for (let i = 0; i < s.length; ++i) {
        buffer.set([s.charCodeAt(i)], i);
    }
    return buffer.buffer;
}

export function arrayBufferToBase64String(buffer) {
    if (useNodeBuffer) {
        return Buffer.from(buffer).toString("base64");
    }
    const buf = new Uint8Array(buffer);
    let s = "";
    for (let i = 0, l = buf.length; i < l; i++) {
        s += String.fromCharCode(buf[i]);
    }
    return btoa(s);
}

export function ValidationErrorMessage({ message }) {
    return <Text size={"xsmall"} color={"status-critical"}>{message}</Text>
}
