import React from "react";
import Webcam from "react-webcam";
import imageCompression from "browser-image-compression";

import {
    Layer, Box, Heading, Button, Grid, Card, Text, TextArea,
    Form, FormField, TextInput, Tabs, Tab, Image, Meter,
} from "grommet";
import {
    FormClose, FormTrash, AddCircle, CloudUpload, Video, Camera
} from "grommet-icons";

import ModelTypeTag from "../../../tag/model";
import DragDropFile from "../../../../../../components/form/file";
import { LoaderModal } from "../../../../../../components";
import { ModelProvider, saveModel } from "../../../../../../services";
import { train, createTfModel } from "../../../../../../utils/tensorflow";
import { createNotificationMessage, NotificationState } from "../../../../../../utils/notifications";
import { AppContext } from "../../../../../../context";

const DEFAULT_TEXTAREA_HEIGHT = 229;

const DEFAULT_LABEL_VALUE = {
    labelName: "",
};

export default function LearningModel({ modelInfo, onCloseFunc }) {

    const { globalState } = React.useContext(AppContext);

    const [ model, setModel ] = React.useState(undefined);
    const [ trainedModel, setTrainedModel ] = React.useState(undefined);

    const textAreaRef = React.useRef(null);
    const [ description, setDescription ] = React.useState("");

    const [ trainAvailable, setTrainAvailable ] = React.useState(false);
    const [ trainDone, setTrainDone ] = React.useState(false);

    const [ isTraining, setIsTraining ] = React.useState(false);
    const [ isStoring, setIsStoring ] = React.useState(false);
    const [ isAnalyzing, setAnalyzing ] = React.useState(false);

    const [ labels, setLabels ] = React.useState([]);
    const [ selectedLabelIndex, setSelectedLabelIndex ] = React.useState(undefined);

    const removeLabel = (index) => {
        if (selectedLabelIndex === index) {
            setSelectedLabelIndex(undefined);
        }
        else if (selectedLabelIndex > index) {
            setSelectedLabelIndex(prev => prev - 1);
        }
        setLabels(prevState => prevState.filter((value, i) => i !== index));
    };

    const [ labelInputValue, setLabelInputValue ] = React.useState(DEFAULT_LABEL_VALUE);
    const [ labelInputLayerOpen, setLabelInputLayerOpen ] = React.useState(false);
    const openLabelInputLayer = () => setLabelInputLayerOpen(true);
    const closeLabelInputLayer = () => {
        setLabelInputValue(DEFAULT_LABEL_VALUE);
        setLabelInputLayerOpen(false);
    };

    const [ imageUploadLayerOpen, setImageUploadLayerOpen ] = React.useState(false);
    const openImageUploadLayer = () => setImageUploadLayerOpen(true);
    const closeImageUploadLayer = () => {
        setFileImages([]);
        setCaptureImages([]);
        setImageUploadLayerOpen(false);
    };

    const [ uploadImageTabsIndex, setUploadImageTabsIndex ] = React.useState(0);
    const [ fileImages, setFileImages ] = React.useState([]);
    const [ captureImages, setCaptureImages ] = React.useState([]);
    const webcamRef = React.useRef(null);

    const handleFile = async (files) => {
        let imageFiles = [];
        for (let i = 0; i < files.length; i++) {
            imageFiles.push(files[i]);
        }

        const filePromises = imageFiles.map((file) => {
            return new Promise(async (resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = async () => {
                    try {
                        resolve({
                            file: file,
                            data: reader.result,
                        });
                    } catch (err) {
                        reject(err);
                    }
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsDataURL(file);
            });
        });

        const fileImageBlobs = await Promise.all(filePromises);
        setFileImages(prevState => {
            let newItems = [...prevState];
            for (let i = 0; i < fileImageBlobs.length; i++) {
                newItems.push(fileImageBlobs[i]);
            }
            return newItems;
        });
    };

    const removeSelectedUploadImage = (index) => {
        setFileImages(prevState => {
            return prevState.filter((_, i) => i !== index);
        });
    };

    const insertLabelImages = async () => {
        let images;

        if (uploadImageTabsIndex === 0) {
            const filePromises = fileImages.map((file) => {
                return new Promise(async (resolve, reject) => {
                    const reader = new FileReader();
                    const compressedImage = await imageCompression(file.file, {
                        maxWidthOrHeight: 348,
                        fileType: "image/jpeg",
                    });
                    reader.onloadend = async () => {
                        try {
                            resolve(reader.result);
                        } catch (err) {
                            reject(err);
                        }
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(compressedImage);
                });
            });

            images = await Promise.all(filePromises);
        }
        else {
            images = captureImages;
        }

        setLabels(prevState => {
            let newLabels = [];
            prevState.forEach((label, i) => {
                if (selectedLabelIndex === i) {
                    newLabels.push({
                        name: label.name,
                        images: label.images.concat(images),
                    });
                } else {
                    newLabels.push(label);
                }
            });
            return newLabels;
        });

        closeImageUploadLayer();
    };

    const capture = React.useCallback(() => {
        const image = webcamRef.current?.getScreenshot();
        if (image) {
            setCaptureImages(prevState => [...prevState, image]);
        }
    }, [webcamRef]);

    const removeSelectedCapturedImage = (index) => {
        setCaptureImages(prevState => {
            return prevState.filter((_, i) => i !== index);
        });
    };

    const onClose = () => {
        setTrainedModel(undefined);
        setModel(undefined);
        setIsTraining(false);
        setIsStoring(false);
        setAnalyzing(false);
        setLabels([]);
        setTrainAvailable(false);
        setTrainDone(false);
        onCloseFunc();
    };

    const trainStart = async () => {
        setIsTraining(true);

        const result = await train(model.name, labels);
        setTrainedModel(result);
        setTrainDone(true);

        setIsTraining(false);
    };

    const testWebcamRef = React.useRef(null);
    const [ testImageTabsIndex, setTestImageTabsIndex ] = React.useState(0);
    const [ testImage, setTestImage ] = React.useState(undefined);
    const [ testResult, setTestResult ] = React.useState(undefined);
    const [ modelTestLayerOpen, setModelTestLayerOpen ] = React.useState(false);
    const openModelTestLayer = () => setModelTestLayerOpen(true);
    const closeModelTestLayer = () => {
        setTestImage(undefined);
        setTestResult(undefined);
        setModelTestLayerOpen(false);
    };

    const handleTestImageFile = (file) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            setTestImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const captureTestImage = React.useCallback(() => {
        const image = testWebcamRef.current?.getScreenshot();
        if (image) {
            setTestImage(image);
        }
    }, [testWebcamRef]);

    React.useEffect(() => {
        if (testImage !== undefined && model.provider === ModelProvider.Tensorflow) {
            const analyze = async () => {
                setAnalyzing(true);
                createTfModel({ provider: ModelProvider.Tensorflow, model: trainedModel }, labels)
                    .then(tfModel => tfModel.predict(testImage))
                    .then(result => setTestResult(result))
                    .catch(error => {
                        console.error(error);
                        createNotificationMessage(NotificationState.FAILED, "이미지를 분석하는데 실패했습니다.");
                    })
                    .finally(() => setAnalyzing(false));
            };
            analyze().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testImage]);

    const test = (imageComponent) => {
        if (model.provider === ModelProvider.TeachableMachine) {
            setAnalyzing(true);
            createTfModel({ provider: ModelProvider.Tensorflow, model: trainedModel }, labels)
                .then(tfModel => tfModel.predict(imageComponent))
                .then(result => setTestResult(result))
                .catch(error => {
                    console.error(error);
                    createNotificationMessage(NotificationState.FAILED, "이미지를 분석하는데 실패했습니다.");
                })
                .finally(() => setAnalyzing(false));
        }
    };

    const save = async () => {
        setIsStoring(true);

        const result = await saveModel(model, labels, trainedModel, globalState.user.id);
        setIsStoring(false);

        if (result) {
            onClose();
        }
    };

    React.useEffect(() => {
        setModel({
            provider: ModelProvider.Tensorflow,
            type: modelInfo.modelType,
            name: modelInfo.modelName,
        });
    }, [modelInfo]);

    React.useEffect(() => {
        let available = true;
        if (labels.length < 2) {
            available = false;
        }
        else {
            labels.forEach(label => {
                available = available && (label.images.length > 2);
            });
        }
        setTrainAvailable(available);
    }, [labels]);

    return (
        <>
            { isTraining === true && <LoaderModal message={"학습 중..."} /> }
            { isStoring === true &&  <LoaderModal message={"서버에 저장 중..."} /> }
            { isAnalyzing === true &&  <LoaderModal message={"분석 중..."} /> }

            { imageUploadLayerOpen === true &&
                <Layer position={"center"}
                       style={{ borderRadius: 24 }}
                       modal
                       responsive
                >
                    <Box pad={{ vertical: "large", horizontal: "large" }} width={"1024px"} height={"581px"}>
                        <Tabs activeIndex={uploadImageTabsIndex} onActive={nextIndex => setUploadImageTabsIndex(nextIndex)} flex>
                            <Tab icon={<CloudUpload size={"18px"} />}
                                 title={
                                     <Text size={"small"} weight={uploadImageTabsIndex === 0 ? "bold" : "normal"}>
                                         이미지 업로드
                                     </Text>
                                 }
                            >
                                <Grid columns={["360px", "auto"]} height={"100%"} gap={"medium"} pad={{ vertical: "medium" }}>
                                    <Box>
                                        <DragDropFile width={"360px"} height={"360px"} handleFile={handleFile} multiple={true} />
                                    </Box>
                                    <Box overflow={"auto"} flex className={"hide-scrollbar"} pad={{ bottom: "small" }}>
                                        <Grid columns={{ count: 4, size: "auto" }} gap={"medium"}>
                                            { fileImages.map((file, index) => (
                                                <Card key={index} overflow={"hidden"}
                                                      style={{ width: "100%", paddingTop: "100%", position: "relative" }}
                                                      animation={{ type: "zoomIn", delay: index * 50 }}
                                                >
                                                    <Box style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
                                                         className={"hover-overlay-container"}
                                                    >
                                                        <Image src={file.data} fit={"contain"} />
                                                        <Button plain
                                                                icon={
                                                                    <Box gap={"xxsmall"} align={"center"} background={"transparent"}
                                                                         pad={{ horizontal: "small" }}
                                                                    >
                                                                        <Text size={"xsmall"} truncate>
                                                                            { file.name }
                                                                        </Text>
                                                                        <FormTrash size={"16px"} />
                                                                    </Box>
                                                                }
                                                                onClick={() => removeSelectedUploadImage(index)}
                                                        />
                                                    </Box>
                                                </Card>
                                            )) }
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Tab>
                            <Tab icon={<Video size={"18px"} />}
                                 title={
                                     <Text size={"small"} weight={uploadImageTabsIndex === 1 ? "bold" : "normal"}>
                                         웹캠 캡쳐
                                     </Text>
                                 }
                            >
                                <Grid columns={["360px", "auto"]} height={"100%"} gap={"medium"} pad={{ vertical: "medium" }}>
                                    <Box round={"16px"} width={"360px"} height={"362px"}
                                         border={{ width: "1px", style: "dashed", side: "all" }}
                                         pad={"xsmall"}
                                         className={"webcam-container"}
                                    >
                                        <Webcam ref={webcamRef}
                                                audio={false}
                                                width={348} height={348}
                                                screenshotFormat={"image/jpeg"}
                                                videoConstraints={{
                                                    width: 348,
                                                    height: 348,
                                                }}
                                                style={{ borderRadius: 12 }}
                                        />
                                        <Box fill align={"center"} justify={"center"} className={"camera-button-overlay"}>
                                            <Box round={"medium"} align={"center"} justify={"center"}
                                                 border={{ size: "medium", color: "brand" }}
                                                 width={"60px"} height={"60px"}
                                                 onClick={capture}
                                            >
                                                <Camera />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box overflow={"auto"} flex className={"hide-scrollbar"}>
                                        <Grid columns={{ count: 4, size: "auto" }} gap={"medium"}>
                                            { captureImages.map((image, index) => (
                                                <Card key={index} overflow={"hidden"}
                                                      style={{ width: "100%", paddingTop: "100%", position: "relative" }}
                                                      animation={{ type: "zoomIn", delay: index * 50 }}
                                                >
                                                    <Box style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
                                                         className={"hover-overlay-container"}
                                                    >
                                                        <Image src={image} fit={"cover"} />
                                                        <Button plain icon={<FormTrash size={"16px"} />}
                                                                onClick={() => removeSelectedCapturedImage(index)}
                                                        />
                                                    </Box>
                                                </Card>
                                            )) }
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Tab>
                        </Tabs>
                        <Box direction={"row"} gap={"small"} justify={"end"}>
                            <Button secondary size={"small"} label={"취소"}
                                    onClick={() => closeImageUploadLayer()}
                            />
                            <Button primary size={"small"} label={"업로드"}
                                    onClick={() => insertLabelImages()}
                            />
                        </Box>
                    </Box>
                </Layer>
            }

            { /* Label Input Layer */ }
            { labelInputLayerOpen === true &&
                <Layer position={"center"}
                       style={{ borderRadius: 24 }}
                       modal
                       responsive
                >
                    <Box pad={{ vertical: "24px", horizontal: "32px" }} fill>
                        <Heading level={"4"} weight={"bold"}>레이블 등록</Heading>
                        <Form value={labelInputValue}
                              validate={"blur"}
                              onChange={(nextValue) => setLabelInputValue(nextValue)}
                              onReset={() => closeLabelInputLayer()}
                              onSubmit={() => {
                                  setLabels(labels.concat({ name: labelInputValue.labelName, images: [] }));
                                  closeLabelInputLayer();
                              }}
                        >
                            <FormField label={<Text size={"small"}>레이블 이름</Text>} name={"labelName"}
                                       validate={[
                                           (value) => {
                                               if (!value || value.length < 1) return "필수 입력 사항입니다.";
                                               else return undefined;
                                           }
                                       ]}
                            >
                                <TextInput name={"labelName"} type={"text"} size={"small"} width={"240px"} />
                            </FormField>
                            <Box direction={"row"} gap={"small"} justify={"end"} margin={{ top: "32px" }}>
                                <Button size={"small"} type={"reset"} label={"취소"} style={{ paddingBottom: 6 }} />
                                <Button size={"small"} type={"submit"}
                                        label={<Text size={"small"} color={"text"} weight={"bold"}>등록</Text>}
                                        primary style={{ paddingBottom: 6 }}
                                />
                            </Box>
                        </Form>
                    </Box>
                </Layer>
            }

            { modelTestLayerOpen === true &&
                <Layer position={"center"}
                       style={{ borderRadius: 24 }}
                       modal
                       responsive
                >
                    <Box pad={{ vertical: "large", horizontal: "large" }} width={"1024px"} height={"581px"} justify={"between"}>
                        <Grid columns={["360px", "auto"]} height={"100%"} gap={"medium"}>
                            <Tabs activeIndex={testImageTabsIndex} flex
                                  onActive={nextIndex => {
                                      setTestImage(undefined);
                                      setTestResult(undefined);
                                      setTestImageTabsIndex(nextIndex);
                                  }}
                            >
                                <Tab icon={<CloudUpload size={"18px"} />}
                                     title={
                                         <Text size={"small"} weight={testImageTabsIndex === 0 ? "bold" : "normal"}>
                                             이미지 업로드
                                         </Text>
                                     }
                                >
                                    <Box margin={{ top: "24px" }}>
                                        { testImage === undefined &&
                                            <DragDropFile width={"360px"} height={"360px"} handleFile={handleTestImageFile} />
                                        }
                                        { testImage !== undefined &&
                                            <Card pad={"none"} overflow={"hidden"} height={"360px"} animation={{ type: "fadeIn" }}>
                                                <Image id={"preview"} src={testImage} fit={"contain"}
                                                       onLoad={() => test(document.getElementById("preview"))}
                                                />
                                            </Card>
                                        }
                                    </Box>
                                </Tab>
                                <Tab icon={<Video size={"18px"} />}
                                     title={
                                         <Text size={"small"} weight={testImageTabsIndex === 1 ? "bold" : "normal"}>
                                             웹캠 캡쳐
                                         </Text>
                                     }
                                >
                                    <Box margin={{ top: "24px" }}>
                                        { testImage === undefined &&
                                            <Box round={"16px"} width={"360px"} height={"362px"}
                                                 border={{ width: "1px", style: "dashed", side: "all" }}
                                                 pad={"xsmall"}
                                                 className={"webcam-container"}
                                            >
                                                <Webcam ref={testWebcamRef}
                                                        audio={false}
                                                        width={348} height={348}
                                                        screenshotFormat={"image/jpeg"}
                                                        videoConstraints={{
                                                            width: 348,
                                                            height: 348,
                                                        }}
                                                        style={{ borderRadius: 12 }}
                                                />
                                                <Box round={"medium"} align={"center"} justify={"center"}
                                                     border={{ size: "medium", color: "brand" }}
                                                     width={"60px"} height={"60px"}
                                                     className={"camera-button-overlay"}
                                                     onClick={captureTestImage}
                                                >
                                                    <Camera />
                                                </Box>
                                            </Box>
                                        }
                                        { testImage !== undefined &&
                                            <Card pad={"none"} overflow={"hidden"} animation={{ type: "fadeIn" }}>
                                                <Image id={"preview"} src={testImage} fit={"cover"}
                                                       onLoad={() => test(document.getElementById("preview"))}
                                                />
                                            </Card>
                                        }
                                    </Box>
                                </Tab>
                            </Tabs>
                            <Box flex margin={{ top: "65px" }}>
                                { testResult !== undefined &&
                                    <Card align={"center"} justify={"center"} gap={"24px"} pad={{ vertical: "medium" }}
                                          height={"360px"} animation={{ type: "fadeIn", delay: 200 }}
                                    >
                                        <Meter size={"180px"} background={"brand"} type={"pie"} values={testResult} />
                                        <Box>
                                            { testResult.map((result, index) => (
                                                <Box key={index} direction={"row"} gap={"xxsmall"}>
                                                    <Box width={"10px"} background={`graph-${index % 4}`}
                                                         margin={{ top: "4px", bottom: "3px" }}
                                                         round={"3px"}
                                                    />
                                                    <Text size={"xsmall"} weight={result.weight}>
                                                        { `${result.label} (${result.value})%` }
                                                    </Text>
                                                </Box>
                                            )) }
                                        </Box>
                                    </Card>
                                }
                            </Box>
                        </Grid>
                        <Box direction={"row"} gap={"small"} justify={"end"}>
                            <Button secondary size={"small"} label={"닫기"}
                                    onClick={() => closeModelTestLayer()}
                            />
                            <Button primary size={"small"} label={"초기화"}
                                    onClick={() => {
                                        setTestImage(undefined);
                                        setTestResult(undefined);
                                    }}
                            />
                        </Box>
                    </Box>
                </Layer>
            }

            <Layer position={"center"}
                   margin={"medium"}
                   style={{ borderRadius: 24 }}
                   modal
                   responsive
                   full
            >
                <Box pad={{ vertical: "medium", horizontal: "large" }} fill>
                    <Box style={{ position: "absolute", top: 36, right: 36 }}>
                        <Button icon={<FormClose />} onClick={() => onClose()} style={{ borderRadius: 14 }} />
                    </Box>
                    <Heading level={"3"} margin={{ bottom: "30px" }}>모델 학습</Heading>
                    <Box direction={"column"} gap={"medium"} fill align={"center"} justify={"center"}>
                        <Grid columns={["540px", "flex"]} gap={"medium"} fill>
                            <Grid rows={["auto", "flex", "36px"]} gap={"medium"} fill>
                                <Card gap={"small"} pad={"16px"} round={"medium"}>
                                    { model !== undefined &&
                                        <Box gap={"16px"} pad={{ top: "xxsmall", horizontal: "xsmall" }}>
                                            <ModelTypeTag provider={model.provider} type={model.type} />
                                            <Box pad={{ horizontal: "xxsmall" }} align={"start"}>
                                                <Text weight={"bold"}>
                                                    { model.name }
                                                </Text>
                                            </Box>
                                            <Box pad={{ bottom: "xxsmall" }}>
                                                <TextArea size={"small"} placeholder={"모델에 대한 설명을 입력해 주세요."}
                                                          ref={textAreaRef}
                                                          value={description}
                                                          style={{ height: DEFAULT_TEXTAREA_HEIGHT, borderRadius: 16 }}
                                                          onChange={(event) => setDescription(event.target.value)}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                </Card>
                                <Card gap={"small"} pad={"16px"} round={"medium"}>
                                    <Box pad={"xsmall"} gap={"xsmall"}>
                                        <Text size={"medium"} weight={"bold"}>레이블 정보</Text>
                                        <Text size={"xsmall"} color={"text-weak"}>
                                            학습을 위해서는 최소한 2개 이상의 레이블이 필요합니다.
                                        </Text>
                                    </Box>
                                    <Box direction={"row"} gap={"medium"} pad={{ horizontal: "xsmall", top: "xsmall", bottom: "small" }} wrap overflow={"auto"}>
                                        { labels.map((label, index) =>
                                            <Card key={index} className={"unselectable"}
                                                  hoverIndicator={selectedLabelIndex !== index}
                                                  background={index === selectedLabelIndex ? "brand" : undefined}
                                                  direction={"row"} gap={"32px"}
                                                  margin={{ bottom: "small" }}
                                                  pad={{ horizontal: "16px", vertical: "8px" }}
                                                  onFocus={e => e.target.blur()}
                                                  onClick={() => {
                                                      if (index === selectedLabelIndex) {
                                                          setSelectedLabelIndex(undefined);
                                                      }
                                                      else {
                                                          setSelectedLabelIndex(index);
                                                      }
                                                  }}
                                            >
                                                <Text size={"small"} weight={"bolder"}>{ label.name }</Text>
                                                <Button plain icon={<FormClose size={"18px"} style={{ marginRight: -4 }} />}
                                                        style={{ zIndex: 100 }}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            removeLabel(index);
                                                        }}
                                                />
                                            </Card>
                                        )}
                                        <Card className={"unselectable"}
                                              hoverIndicator={{ color: "brand" }}
                                              direction={"row"} gap={"8px"}
                                              margin={{ bottom: "small" }}
                                              pad={{ horizontal: "16px", vertical: "8px" }}
                                              onFocus={e => e.target.blur()}
                                              onClick={() => openLabelInputLayer()}
                                        >
                                            <AddCircle size={"16px"} style={{ marginTop: 2 }} />
                                            <Text size={"small"}>레이블 추가</Text>
                                        </Card>
                                    </Box>
                                </Card>
                                <Grid columns={ trainDone ? "1/3" : "auto" } justify={"start"} direction={"row"} gap={"small"}>
                                    { trainDone === true &&
                                        <Button primary size={"small"} label={"저장하기"} fill
                                            onClick={() => save()}
                                        />
                                    }
                                    { trainDone === true &&
                                        <Button secondary size={"small"} label={"모델 테스트"} fill
                                                onClick={() => openModelTestLayer()}
                                        />
                                    }
                                    <Button primary={!trainDone}
                                            secondary={trainDone}
                                            size={"small"} label={trainDone ? "다시 학습" : "학습 시작"} fill
                                            disabled={!trainAvailable}
                                            onClick={() => trainStart()}
                                    />
                                </Grid>
                            </Grid>
                            <Box>
                                { selectedLabelIndex !== undefined &&
                                    <Card gap={"small"} pad={"16px"} round={"medium"} fill animation={"zoomIn"}>
                                        <Box key={selectedLabelIndex} gap={"medium"} animation={"fadeIn"} fill>
                                            <Box pad={{ horizontal: "small" }} gap={"medium"}>
                                                <Box gap={"small"} align={"start"} margin={{ top: "xxsmall" }}>
                                                    <Text weight={"bold"}>
                                                        { labels[selectedLabelIndex].name }
                                                    </Text>
                                                    <Text size={"xsmall"} color={"text-weak"}>
                                                        학습을 위해서는 최소한 3장 이상의 이미지가 필요합니다.
                                                    </Text>
                                                </Box>
                                                <Box align={"start"}>
                                                    <Button secondary size={"small"}
                                                            label={"이미지 추가"}
                                                            onClick={() => openImageUploadLayer()}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box flex overflow={"auto"} className={"hide-scrollbar"}>
                                                <Grid columns={"150px"} gap={"medium"} margin={{ bottom: "small" }}>
                                                    { labels[selectedLabelIndex].images.map((image, index) => (
                                                        <Card key={index} overflow={"hidden"}
                                                              style={{ width: "100%", paddingTop: "100%", position: "relative" }}
                                                              animation={{ type: "fadeIn", delay: index * 50 }}
                                                        >
                                                            <Box style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
                                                                 className={"hover-overlay-container"}
                                                            >
                                                                <Image src={image} fit={"contain"} />
                                                                <Button plain size={"small"} gap={"xxsmall"}
                                                                        icon={<FormTrash size={"18px"} />}
                                                                        label={<Text size={"small"} margin={{ top: "2px" }}>삭제</Text>}
                                                                        onClick={() => {}}
                                                                />
                                                            </Box>
                                                        </Card>
                                                    )) }
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Card>
                                }
                            </Box>
                        </Grid>
                    </Box>
                </Box>
            </Layer>
        </>
    );

}
