import React from "react";

import {
    Box, Button, Heading, Layer, Grid, Card, Text, TextArea,
    List, CheckBox, Image
} from "grommet";
import {
    FormClose, StatusGood, StatusWarning, Checkmark, Subtract, Refresh
} from "grommet-icons";

import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import imageCompression from "browser-image-compression";

import CustomEditor from "../../../../../../components/editor";
import ModelTypeTag from "../../../tag/model";
import DragDropFile from "../../../../../../components/form/file";
import { LoaderModal } from "../../../../../../components";
import { FILE_RESIZE_OPTION } from "../../../../../../config/file";
import { AppContext } from "../../../../../../context";
import { saveApp } from "../../../../../../services";

const DEFAULT_TEXTAREA_HEIGHT = 235;

export default function SetupApp({ inputValue, onCloseFunc }) {

    const { globalState } = React.useContext(AppContext);

    const textAreaRef = React.useRef(null);

    const [ description, setDescription ] = React.useState("");
    const [ coverImage, setCoverImage ] = React.useState(undefined);

    const [ labels, setLabels ] = React.useState([]);
    const [ selected, setSelected ] = React.useState([]);
    const [ selectedIndex, setSelectedIndex ] = React.useState(undefined);

    const [ labelDescription, setLabelDescription ] = React.useState(() => EditorState.createEmpty());

    const [ storeAvailable, setStoreAvailable ] = React.useState(false);
    const [ isStoring, setIsStoring ] = React.useState(false);
    const [ inProgress, setInProgress ] = React.useState(false);

    const onClose = () => {
        setStoreAvailable(false);
        setLabelDescription(EditorState.createEmpty());
        setSelectedIndex(undefined);
        setSelected([]);
        setLabels([]);
        setCoverImage(undefined);
        setDescription(undefined);

        onCloseFunc();
    };

    const handleCoverImage = (file) => {
        setInProgress(true);
        imageCompression(file, FILE_RESIZE_OPTION)
            .then(compressedImage => {
                let reader = new FileReader();
                reader.onloadend = () => {
                    setCoverImage({
                        blob: compressedImage,
                        buffer: reader.result,
                    });
                    setInProgress(false);
                };
                reader.readAsDataURL(compressedImage);
            })
            .catch(error => {
                console.error(error);
                setInProgress(false);
            });
    };

    const removeCoverImage = () => {
        setCoverImage(undefined);
    };

    const selectAllLabels = () => {
        if (selected.length === labels.length) {
            setSelected([]);
        }
        else {
            let array = [];
            labels.forEach((l, i) => {
                array.push(i);
            });
            setSelected(array);
        }
    };

    const selectLabel = (index) => {
        const checked = selected.filter(i => i === index).length > 0;
        if (checked) {
            setSelected(prevState => [...prevState.filter(i => i !== index)]);
        }
        else {
            setSelected(prevState => [...prevState, index]);
        }
    };

    const onClickLabelRow = (index) => {
        if (selectedIndex === index) {
            setSelectedIndex(undefined);
            setLabelDescription(EditorState.createEmpty());
        }
        else {
            setSelectedIndex(index);
            if (labels[index].description === undefined) {
                setLabelDescription(EditorState.createEmpty());
            }
            else {
                setLabelDescription(labels[index].description);
            }
        }
    };

    const saveLabelDescription = (index) => {
        const newLabels = labels.map((label, i) => {
            if (i === index) {
                return {
                    name: label.name,
                    description: labelDescription,
                }
            }
            else {
                return label;
            }
        });

        setLabels(newLabels);
    };

    const store = async () => {
        setIsStoring(true);

        let selectedLabels = [], convertedLabelDescription;
        selected.forEach(i => {
            convertedLabelDescription = convertToHTML({
                blockToHTML: b => {
                    if (b.type === "atomic") {
                        return {
                            start: "<figure>",
                            end: "</figure>",
                            empty: ""
                        };
                    }
                },
                entityToHTML: (entity, originalText) => {
                    if (entity.type === "LINK") {
                        return <a href={entity.data["url"]}>{originalText}</a>;
                    }

                    if (entity.type === "image") {
                        return { start: `<img src='${entity.data["src"]}' alt="">`, end: "</img>", empty: "" };
                    }

                    return originalText;
                },
            })(labels[i].description.getCurrentContent());

            selectedLabels.push({
                name: labels[i].name,
                description: convertedLabelDescription,
            });
        });

        const app = {
            name: inputValue.name,
            model: {
                id: inputValue.model.id,
                provider: inputValue.model.provider,
                type: inputValue.model.type,
                name: inputValue.model.name,
            },
            description: description,
            labels: selectedLabels,
        };

        if (inputValue.model.externalUrl) {
            app.model.externalUrl = inputValue.model.externalUrl;
        }
        else {
            app.model.metadata = inputValue.model.metadata;
            app.model.weights = inputValue.model.weights;
        }

        const success = await saveApp(app, coverImage, globalState.user.id);
        setIsStoring(false);

        if (success) {
            onClose();
        }
    };

    React.useEffect(() => {
        let temp = [];
        inputValue.model.labels.forEach(label => {
            temp.push({
                name: label,
                description: undefined,
            });
        });
        setLabels(temp);
    }, [inputValue]);

    React.useEffect(() => {
        let available = true;

        if (selected.length > 1) {
            selected.forEach(i => {
                if (labels[i].description === undefined) {
                    available = false;
                }
            });
        }
        else {
            available = false;
        }

        setStoreAvailable(available);
    }, [labels, selected]);

    return (
        <>
            { inProgress === true &&  <LoaderModal message={"처리 중..."} /> }
            { isStoring === true &&  <LoaderModal message={"서버에 저장 중..."} /> }
            <Layer position={"center"}
                   margin={"medium"}
                   style={{ borderRadius: 24 }}
                   modal
                   responsive
                   full
            >
                <Box pad={{ vertical: "medium", horizontal: "large" }} fill>
                    <Box style={{ position: "absolute", top: 36, right: 36 }}>
                        <Button icon={<FormClose />} onClick={() => onClose()} style={{ borderRadius: 14 }} />
                    </Box>
                    <Heading level={"3"} margin={{ bottom: "30px" }}>앱 설정</Heading>
                    <Box direction={"column"} gap={"medium"} fill align={"center"} justify={"center"}>
                        <Grid columns={["720px", "flex"]} gap={"medium"} fill>
                            <Grid rows={["auto", "flex", "36px"]} gap={"medium"} fill>
                                <Card gap={"small"} pad={"16px"} round={"medium"}>
                                    <Box gap={"16px"} pad={{ top: "xxsmall", horizontal: "xsmall" }}>
                                        <ModelTypeTag provider={inputValue.model.provider} type={inputValue.model.type} />
                                        <Box pad={{ horizontal: "xxsmall" }} align={"start"}>
                                            <Text weight={"bold"}>
                                                { inputValue.name }
                                            </Text>
                                        </Box>
                                        <Grid pad={{ bottom: "xxsmall" }} columns={[`${DEFAULT_TEXTAREA_HEIGHT}px`, "auto"]} gap={"small"}>
                                            { coverImage === undefined ?
                                                <DragDropFile id={"coverImage"} width={`${DEFAULT_TEXTAREA_HEIGHT}px`} height={`${DEFAULT_TEXTAREA_HEIGHT}px`}
                                                              handleFile={handleCoverImage}
                                                /> :
                                                <Box style={{ position: "relative", top: 0, bottom: 0, left: 0, right: 0 }}
                                                     width={`${DEFAULT_TEXTAREA_HEIGHT}px`} height={`${DEFAULT_TEXTAREA_HEIGHT}px`}
                                                     round={"small"} overflow={"hidden"}
                                                     className={"hover-overlay-container"}
                                                >
                                                    <Image src={coverImage.buffer} fit={"cover"} />
                                                    <Button plain
                                                            icon={
                                                                <Box gap={"xxsmall"} align={"center"} background={"transparent"}
                                                                     pad={{ horizontal: "small" }}
                                                                >
                                                                    <Refresh size={"16px"} />
                                                                    <Text size={"xsmall"} truncate>
                                                                        다시 선택
                                                                    </Text>
                                                                </Box>
                                                            }
                                                            onClick={() => removeCoverImage()}
                                                    />
                                                </Box>
                                            }
                                            <TextArea size={"small"} placeholder={"등록할 앱에 대한 설명을 입력해 주세요."}
                                                      ref={textAreaRef}
                                                      value={description}
                                                      style={{ height: DEFAULT_TEXTAREA_HEIGHT, borderRadius: 16, padding: 16 }}
                                                      onChange={(event) => setDescription(event.target.value)}
                                            />
                                        </Grid>
                                    </Box>
                                </Card>
                                <Card gap={"small"} pad={"16px"} round={"medium"}>
                                    <Box pad={"xsmall"} gap={"xsmall"}>
                                        <Text size={"medium"} weight={"bold"}>레이블 정보</Text>
                                        <Text size={"xsmall"} color={"text-weak"}>
                                            앱 등록을 위해서 최소한 2개 이상의 레이블을 선택해야 합니다.
                                        </Text>
                                    </Box>
                                    <Box pad={{ horizontal: "xsmall", top: "xsmall", bottom: "small" }} overflow={"auto"} className={"hide-scrollbar"}>
                                        <Box direction={"row"} gap={"medium"} align={"center"}
                                             border={{ side: "bottom" }} pad={{ vertical: "small", horizontal: "xsmall" }} margin={{ bottom: "xsmall" }}
                                        >
                                            <CheckBox onClick={() => selectAllLabels()}>
                                                {() => (
                                                    <Box width={"24px"} height={"24px"} align={"center"} justify={"center"}
                                                         border={{ size: "1px" }} round={"4px"}
                                                         background={selected.length === 0 ? "transparent" : "brand"}
                                                    >
                                                        { selected.length === labels.length ? <Checkmark size={"14px"} /> : (
                                                            selected.length === 0 ? undefined : <Subtract size={"14px"} />
                                                        )}
                                                    </Box>
                                                )}
                                            </CheckBox>
                                            <Text size={"small"} weight={"bold"}>
                                                레이블
                                            </Text>
                                        </Box>
                                        <List data={labels}
                                              itemKey={(item) => (item.name)}
                                              itemProps={
                                                  selectedIndex >= 0 ? { [selectedIndex]: { background: "brand" } } : undefined
                                              }
                                              pad={"xsmall"}
                                        >
                                            {(datum, index) => (
                                                <Box key={index} direction={"row"} gap={"medium"} align={"center"}>
                                                    <CheckBox checked={selected.filter(i => i === index).length > 0}
                                                              onClick={() => selectLabel(index)}
                                                    />
                                                    <Box flex direction={"row"} justify={"between"} align={"center"}
                                                         className={"disabled-focus"}
                                                         onClick={() => onClickLabelRow(index)}
                                                    >
                                                        <Text size={"small"}>
                                                            { datum.name }
                                                        </Text>
                                                        <Box width={"xxsmall"} align={"center"}>
                                                            { datum.description !== undefined ?
                                                                <StatusGood color={"status-ok"}/>
                                                                : <StatusWarning color={"status-critical"} />
                                                            }
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )}
                                        </List>
                                    </Box>
                                </Card>
                                <Box justify={"start"}>
                                    <Button primary size={"small"} label={"앱 설정 저장"} style={{ width: 160 }}
                                            disabled={!storeAvailable}
                                            onClick={() => store()}
                                    />
                                </Box>
                            </Grid>
                            <Box>
                                { selectedIndex !== undefined &&
                                    <Card pad={"16px"} round={"medium"} fill animation={"zoomIn"}>
                                        <Box key={selectedIndex} gap={"small"} animation={"fadeIn"} fill>
                                            <Box pad={"small"}>
                                                <Text weight={"bold"}>
                                                    { labels[selectedIndex].name }
                                                </Text>
                                            </Box>
                                            <Box flex>
                                                <CustomEditor editorState={labelDescription} setEditorState={setLabelDescription} />
                                            </Box>
                                            <Box direction={"row"} gap={"small"} justify={"end"}>
                                                <Button secondary size={"small"} label={"초기화"}
                                                        onClick={() => setLabelDescription(EditorState.createEmpty())}
                                                />
                                                <Button primary size={"small"} label={"저장"}
                                                        onClick={() => saveLabelDescription(selectedIndex)}
                                                />
                                            </Box>
                                        </Box>
                                    </Card>
                                }
                            </Box>
                        </Grid>
                    </Box>
                </Box>
            </Layer>
        </>
    );

}
