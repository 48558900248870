import process from "process";

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const BROWSER_STORAGE_KEY = (value) => (
    isDev ? `AI_BADA_DEV_DATA_${value}` : `AI_BADA_SERVICE_DATA_${value}`
);

export const removeLocalStorageValue = (key) => {
    localStorage.removeItem(BROWSER_STORAGE_KEY(key));
};

export const setLocalStorageValue = (key, value) => {
    localStorage.setItem(BROWSER_STORAGE_KEY(key), value)
};

export const getLocalStorageValue = (key) => {
    return localStorage.getItem(BROWSER_STORAGE_KEY(key));
};

export const setSessionStorageValue = (key, value) => {
    sessionStorage.setItem(BROWSER_STORAGE_KEY(key), value);
};

export const getSessionStorageValue = (key) => {
    return sessionStorage.getItem(BROWSER_STORAGE_KEY(key));
};
