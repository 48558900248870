import React from "react";
import { motion } from "framer-motion";

import { Box, Card, Button, Form, FormField, Heading, MaskedInput, Text, TextInput } from "grommet";
import { License, FormClose } from "grommet-icons";

import { AppContext } from "../../../context";
import { LoaderModal } from "../../../components";
import { Routes } from "../../../routes";
import { registerWithEmailAndPassword } from "../../../services";

function isEmailValidate(email) {
    return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
}

const DEFAULT_VALUE = {
    email: "",
    password: "",
    confirm: "",
};

export default function Register({ onClose }) {

    const { globalState, handleAuthPath } = React.useContext(AppContext);
    const isDarkMode = globalState.themeMode === "dark";

    const [ value, setValue ] = React.useState(DEFAULT_VALUE);
    const [ isInProgress, setInProgress ] = React.useState(false);

    const reset = () => {
        setValue(DEFAULT_VALUE);
        handleAuthPath({ path: Routes.AUTH_PATH.Login });
    };

    const submit = async (registerValue) => {
        setInProgress(true);
        await registerWithEmailAndPassword(registerValue.email, registerValue.password);
        handleAuthPath({ path: Routes.AUTH_PATH.VerifyingEmail });
        setInProgress(false);
    };

    return (
        <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{ width: "100%", height: "100%" }}
        >
            { isInProgress === true &&  <LoaderModal message={"처리 중..."} /> }
            <Card width={"360px"} round={"medium"}>
                <Box style={{ position: "absolute", top: 24, right: 24 }}>
                    <Button icon={<FormClose />} onClick={onClose} style={{ borderRadius: 14 }} />
                </Box>
                <Box pad={"large"} gap={"medium"}>
                    <Box direction={"row"} gap={"medium"}>
                        <License size={"36px"} style={{ alignSelf: "center" }} />
                        <Heading level={"3"} style={{ fontWeight: 900, paddingTop: 3 }}>
                            { Routes.TITLES.Register }
                        </Heading>
                    </Box>
                    <Form value={value}
                          validate={"blur"}
                          onChange={(nextValue) => setValue(nextValue)}
                          onSubmit={(event) => submit(event.value)}
                          onReset={() => reset()}
                    >
                        <FormField label={"이메일"} htmlFor={"email"} name={"email"} margin={{ bottom: "8px" }}
                                   validate={[
                                       (email) => {
                                           if (!email || email.length < 1) return "필수 입력 사항입니다.";
                                           else if (!isEmailValidate(email)) return "잘못된 이메일 주소입니다.";
                                           else return undefined;
                                       }
                                   ]}
                        >
                            <MaskedInput id={"email"} name={"email"} size={"small"}
                                         mask={[
                                             { regexp: /^[\w\-_.]+$/, placeholder: "gbu" },
                                             { fixed: "@" },
                                             { regexp: /^[\w]+$/, placeholder: "gbudatalinks" },
                                             { fixed: "." },
                                             { regexp: /^[\w]+$/, placeholder: "com" },
                                         ]}
                            />
                        </FormField>
                        <FormField label={"패스워드"} htmlFor={"password"} name={"password"} margin={{ bottom: "8px" }}
                                   validate={[
                                       (password) => {
                                           if (!password || password.length < 1) return "필수 입력 사항입니다.";
                                           else if (password.length < 6) return "패스워드는 최소 6자 이상으로 입력하세요.";
                                           else return undefined;
                                       }
                                   ]}
                        >
                            <TextInput id={"password"} name={"password"} type={"password"} size={"small"} />
                        </FormField>
                        <FormField label={"패스워드 확인"} htmlFor={"confirm"} name={"confirm"}
                                   validate={[
                                       (confirm) => {
                                           if (!confirm || confirm.length < 1) return "필수 입력 사항입니다.";
                                           else if (value !== undefined && value.password !== confirm) return "패스워드가 일치하지 않습니다.";
                                           else return undefined;
                                       }
                                   ]}
                        >
                            <TextInput id={"confirm"} name={"confirm"} type={"password"} size={"small"} />
                        </FormField>
                        <Box direction={"row"} gap={"small"} justify={"end"} margin={{ top: "medium" }}>
                            <Button type={"reset"} size={"small"}
                                    label={<Text color={"text"} size={"small"}>로그인</Text>}
                            />
                            <Button primary={isDarkMode} secondary={!isDarkMode} type={"submit"} size={"small"}
                                    label={<Text color={"text"} size={"small"}>회원가입</Text>}
                            />
                        </Box>
                    </Form>
                </Box>
            </Card>
        </motion.div>
    );

}
