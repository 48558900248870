import React from "react";
import { motion } from "framer-motion";

import { Box, Card, Button, Heading, Text } from "grommet";
import { FormClose, Mail } from "grommet-icons";

import { AppContext } from "../../../context";
import { waitForEmailVerification } from "../../../services";
import { createNotificationMessage, NotificationState } from "../../../utils/notifications";
import { Routes } from "../../../routes";

export default function VerifyingEmail({ onClose }) {

    const { handleAuthPath } = React.useContext(AppContext);

    React.useEffect(() => {
        waitForEmailVerification()
            .then(() => {
                createNotificationMessage(NotificationState.SUCCESS, "이메일 인증이 완료되었습니다.");
                handleAuthPath({ path: Routes.AUTH_PATH.Login });
            })
            .catch(error => {})
        ;
    }, [handleAuthPath]);

    return (
        <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{ width: "100%", height: "100%" }}
        >
            <Card width={"360px"} round={"medium"}>
                <Box style={{ position: "absolute", top: 24, right: 24 }}>
                    <Button icon={<FormClose />} onClick={onClose} style={{ borderRadius: 14 }} />
                </Box>
                <Box pad={"large"} gap={"medium"}>
                    <Box direction={"row"} gap={"medium"}>
                        <Mail size={"36px"} style={{ alignSelf: "center" }} />
                        <Heading level={"3"} style={{ fontWeight: 900, paddingTop: 3 }}>
                            { Routes.TITLES.Register }
                        </Heading>
                    </Box>
                    <Box gap={"small"} pad={{ bottom: "small" }}>
                        <Text size={"small"}>
                            가입하신 이메일로 인증 메일이 발송되었습니다.
                        </Text>
                        <Text size={"small"}>
                            이메일을 확인해 주세요.
                        </Text>
                    </Box>
                </Box>
            </Card>
        </motion.div>
    );

}
