import React from "react";
import { motion } from "framer-motion";

export default function Settings() {

    return (
        <motion.div initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{ width: "100%", height: "100%" }}
        >
        </motion.div>
    );

}
